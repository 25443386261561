/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {

  &-list {
    list-style: none;
    margin: 0;
    padding: 20px 0;
    display: flex;
  
    li {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      padding-right: 30px;
      font-size: 13px;
      letter-spacing: 0.1em;
  
      @include view-at(sp) {
        font-size: 11px;
      }
  
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-right: 2px solid $color-gray-dark;
        border-top: 2px solid $color-gray-dark;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 5px;
        right: 15px;
      }
      
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
  
}
