/* --------------------------
タブ
-------------------------- */

.c-tab {
  &-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 150px;
    border-bottom: 1px solid $color-gray-light;
    flex-wrap: wrap;
    justify-content: center;
    @include view-at(sp) {
      padding: 0 20px;
      margin: 0 -15px;
    }

    &-item {
      width: calc(100% /2);
      padding: 0 10px;

      @include view-at(sp) {
        padding: 0 3px;
      }

      &:last-child {
        a {
          border-right: 1px solid $color-gray-light;
        }
      }

      a {
        background-color: $color-gray-light;
        border-right: 0;
        display: block;
        padding: 20px 10px;
        text-align: center;
        text-decoration: none;
        color: $color-white;
        font-weight: $bold;
        letter-spacing: 0.1em;
        transition: none;
        letter-spacing: 0.05em;

        @include view-at(sp) {
          border-right: 1px solid $color-gray-light;
          font-size: 11px;
          padding: 15px 10px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1.3em;
        }

        &[aria-selected="true"] {
          padding-bottom: 40px;
          border: 1px solid #c5c5c5;
          border-bottom: 1px solid white;
          margin-bottom: -1px;
          background: none;
          color: $color-main;
          color: #7ACC00;

          @include view-at(sp) {
            padding-bottom: 20px;
            height: 46px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.second {
      padding: 0 40px;
      
      @include view-at(sp) {
        padding: 0 10px;
      }
      li {
        width: calc(100% /4);
      }
    }
    &.third {
      padding: 0 40px;
      flex-wrap: unset;
      @include view-at(sp) {
        padding: 0 10px;
      }
      li {
        width: auto;
        a {
          padding: 20px 30px;
          font-size: 14px;

          &[aria-selected="true"] {
            padding-bottom: 40px;
            color: $color-sub;
            @include view-at(sp) {
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }

  &-contents {
    &-item {
      display: none;
      padding: 30px;

      @include view-at(sp) {
        padding: 30px 0;
      }

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}
