
.entry-content {
  h1 {
    font-size: 30px;
    line-height: 1.5em;
    margin-bottom: 20px;
    @include view-at(sp) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 26px;
    line-height: 1.5em;
    margin-bottom: 20px;
    @include view-at(sp) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 1.5em;
    margin-bottom: 10px;
    @include view-at(sp) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 10px;
    @include view-at(sp) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  h5 {
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 10px;
    font-weight: bold;
    @include view-at(sp) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
  h6 {
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 10px;
    font-weight: bold;
    @include view-at(sp) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  ul {
    @extend .c-list--point;
  }

  ol {
    @extend .c-list--order;
  }

  table {
    @extend .c-table;
  }

  .wp-block-gallery {
    > li {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
  margin: nth($space, 2) 0;
}

.wp-block-quote {
  padding: 20px;
  background-color: $color-gray-snow;
}

.wp-block-image {
  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }
}

.wp-block-table {
  &.aligncenter,
  &.alignleft,
  &.alignright {
    width: 100%;
  }
}

@include view-at(tab) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@include view-at(sp) {
  .wp-block-column + .wp-block-column {
    margin-top: nth($space, 2);
  }
}


.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;

  a {
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
    display: block;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-gray-light;
    font-family: 'Montserrat', sans-serif;
    border: none;
    &:hover {
      background: $color-main;
    }
  }
  span.current {
    background: $color-main;
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
    display: block;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    border: none;
  }
}

#breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
  font-size: 13px;

  a {
    padding-right: 30px;
    position: relative;
    text-decoration: none;
    padding: 0 5px;
  }
  span.breadcrumb_last {
    padding-left: 5px;
  }
}

.wp-page-navigation {
  a {
    border-radius: 40px;
    background: #c5c5c5;
    color: white;
    letter-spacing: 0.1em;
    border: 1px solid #c5c5c5;
    width: 300px;
    display: inline-block;
    padding: 15px 55px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    position: relative;
    font-size: 14px;
    margin: 2px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-right: 2px solid #ababab;
      border-top: 2px solid #ababab;
      transform: rotate(45deg);
      right: 14px;
      top: 45%;
      border-color: white;
    }

    &:nth-child(1) {
      &::after {
        right: auto;
        left: 14px;
        transform: rotate(-135deg);
      }
    }
    &:hover {
      background: #7ACC00;
    }
  }
}
