@charset "UTF-8";
.news {
    &-tab {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        margin-bottom: 40px;
        li {
            padding: 5px;
            width: 16.66666%;
            @include view-at(sp) {
                width: 50%;
            }
            a {
                display: block;
                padding: 15px;
                font-size: 14px;
                text-align: center;
                background: $color-gray-light;
                text-decoration: none;
                color: white;
            }
            &.current {
                a {
                    background: $color-main;
                }
            }
        }
    }

    &-item {
        a {
            text-decoration: none;
            display: block;
            @include view-at(sp) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        &-img {
            position: relative;
            @include view-at(sp) {
                width: 50%;
                padding-right: 20px;
            }
            img {
                box-shadow: 0px 0px 10px #c5c5c5;
            }
            span {
                position: absolute;
                top: 0;
                left: 0;
                padding: 8px 10px;
                background: $color-sub;
                color: white;
                font-size: 12px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    padding: 5px;
                    font-size: 10px;
                }

                &.info {
                    background: $color-btn01;
                }
                &.orient {
                    background: $color-btn02;
                }
                &.tour {
                    background: $color-btn03;
                }
                &.fair {
                    background: $color-btn04;
                }
                &.event {
                    background: $color-btn05;
                }
            }
        }
        &-text {
            padding: 10px 0 0;
            @include view-at(sp) {
                width: 50%;
                padding-top: 0;

                h4 {
                    font-size: 12px;
                    line-height: 1.5;
                }
            }
            &-date {
                font-size: 12px;
                font-family: 'Montserrat', sans-serif;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 10px;
                }
            }
        }
    }

    &-session {
        &-item {
            padding: 20px;
            background: white;
            box-shadow: 0px 0px 10px $color-gray-light;
            @include view-at(sp) {
                padding-bottom: 10px;
            }
            &-date {
                font-size: 12px;
                font-family: 'Montserrat', sans-serif;
                letter-spacing: 0.1em;
                margin-bottom: 5px;
                @include view-at(sp) {
                    font-size: 10px;
                    margin-bottom: 0;
                }
            }
            a {
                text-decoration: none;
            }
            &-gaiyo {
                border: 1px solid $color-gray-light;
                padding: 15px 20px;
                position: relative;
                margin-bottom: 15px;
                @include view-at(sp) {
                    margin-top: 10px;
                }
                h4 {
                    background: white;
                    position: absolute;
                    top: -15px;
                    left: 15px;
                    padding: 5px;
                    letter-spacing: 0.1em;
                    font-size: 14px;
                }
                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    letter-spacing: 0.1em;
                }
            }
            &-contact {
                background: $color-gray-snow;
                padding: 20px;
                margin: -20px;
                margin-top: 0;
                h4 {
                    font-size: 16px;
                    margin-bottom: 10px;
                    text-align: center;
                }
                p {
                    margin-bottom: 10px;
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    &.ttl {
                        border-left: 3px solid #7ACC00;
                        padding-left: 10px;
                    }
                }
                a {
                    &.tel {
                        color: #7ACC00;
                        text-decoration: none;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 25px;
                    }
                    &.c-button {
                        letter-spacing: 0.1em;
                        padding: 10px 40px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}