@charset "UTF-8";

.top {
    position: relative;
    &-bg {
        background: url(../images/top/bg.jpg) no-repeat;
        background-size: cover;
        opacity: 0.2;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
    }

    &-mv {
        overflow: hidden;
    }

    &-news {
        position: relative;
        &-list {
            margin-bottom: 40px;
            a {
                display: block;
                text-decoration: none;

                dl {
                    margin-bottom: 20px;
                    display: flex;
                    letter-spacing: 0.1em;
                    align-items: center;
                    @include view-at(sp) {
                        flex-wrap: wrap;
                    }
                    dt {
                        font-size: 12px;
                        padding-right: 20px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    dd {
                        font-size: 14px;
                        line-height: 1.2em;
                        &:nth-last-child(1) {
                            @include view-at(sp) {
                                width: 100%;
                            }
                        }
                        span {
                            width: 130px;
                            font-size: 10px;
                            background: $color-main;
                            color: white;
                            padding: 10px;
                            text-align: center;
                            display: inline-block;
                            margin-right: 15px;
                            line-height: 1em;
                            @include view-at(sp) {
                                display: block;
                                margin: 0;
                                margin-bottom: 5px;
                                padding: 6px;
                            }
                            &.info {
                                background: $color-btn01;
                            }
                            &.orient {
                                background: $color-btn02;
                            }
                            &.tour {
                                background: $color-btn03;
                            }
                            &.fair {
                                background: $color-btn04;
                            }
                            &.event {
                                background: $color-btn05;
                            }
                        }

                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        
                    }
                }
            }
        }
        
    }
    
    &-recruit {
        &-wrap {
            overflow-y: scroll;
            padding-right: 20px;
            height: 500px;
            @include view-at(sp) {
                padding-right: 10px;
                height: 350px;
                margin-bottom: 20px;
            }
            ul {
                li {
                    padding: 10px;
                    .c-panel-contents--narrow {
                        padding: 15px;
                    }
                    .recruit-new-text {
                        @include view-at(sp) {
                            padding-bottom: 0;
                        }
                    }
                    .recruit-new-link {
                        font-size: 10px;
                        letter-spacing: 0.05em;
                        right: 50px;
                        @include view-at(sp) {
                            bottom: 10px;
                        }
                        &::before {
                            width: 20px;
                            top: 6px;
                            right: -30px;
                        }
                        &::after {
                            top: 3px;
                            right: -31px;
                        }
                    }
                }
            }
        }
    }

    &-link {
        overflow-x: hidden;
        li {
            margin-bottom: 120px;
            height: 500px;

            &:nth-child(1) {
                background-image: url(../images/company/mv.jpg);
                background-size: 70%;
                background-position: right bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(2) {
                background: url(../images/news/mv.jpg);
                background-size: 70%;
                background-position: left bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(3) {
                background-image: url(../images/about/mv.jpg);
                background-size: 70%;
                background-position: right bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(4) {
                background-image: url(../images/other/mv.jpg);
                background-size: 70%;
                background-position: left bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(5) {
                background-image: url(../images/welfare/mv.jpg);
                background-size: 70%;
                background-position: right bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(6) {
                background-image: url(../images/recruit/mv.jpg);
                background-size: 70%;
                background-position: left bottom;
                background-repeat: no-repeat;
            }
            &:nth-child(7) {
                background-image: url(../images/info/mv.jpg);
                background-size: 70%;
                background-position: right bottom;
                background-repeat: no-repeat;
            }

            @include view-at(sp) {
                height: 200px;
                background-size: cover !important;
                margin-bottom: 160px;
                background-position: center !important;
            }

            .c-section-container {
                position: relative;
                height: 100%;
            }
        }
        &-text {
            position: absolute;
            bottom: -60px;
            left: -40px;
            display: flex;
            align-items: center;
            background: white;
            width: 500px;
            @include view-at(sp) {
                width: 80%;
                left: 0;
                bottom: -130px;
            }

            &-inner {
                padding: 60px;
                @include view-at(sp) {
                    padding: 30px;
                }
                h3 {
                    font-size: nth($font-size, 2);
                    line-height: nth($line-height, 2);
                    letter-spacing: 0.08em;
                    font-weight: $normal;
                    margin-bottom: 20px;
                    position: relative;
                    padding-bottom: 20px;

                    @include view-at(sp) {
                        font-size: nth($font-size-sp, 3);
                        line-height: 1.5;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                    }
                    &::before {
                        content: "";
                        width: 150px;
                        height: 2px;
                        background: $color-main;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                a {
                    width: auto;
                    border: none;
                    font-size: 12px;
                    background: none;
                    color: $color-main;
                    padding: 0;

                    &::before {
                        content: "";
                        width: 30px;
                        height: 1px;
                        background: $color-main;
                        position: absolute;
                        top: 8px;
                        right: -40px;
                    }
                    &::after {
                        content: "";
                        width: 10px;
                        height: 1px;
                        border: none;
                        background: $color-main;
                        position: absolute;
                        top: 5px;
                        right: -41px;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            &.right {
                left: unset;
                right: -40px;
                @include view-at(sp) {
                    right: 0;
                }
            }
        }

        
    }

    &-panel {
        display: grid;
        display: -ms-grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        -ms-grid-columns:1fr 1fr 1fr 1fr;
        -ms-grid-rows: 1fr 1fr;
        height: 800px;
        @include view-at(sp) {
            grid-template-columns:1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }
        @include view-at(sp) {
            margin: 1px;
        }
        li {
            margin: 5px;
            position: relative;
            overflow: hidden;
            background: #000;
            
            @include view-at(sp) {
                margin: 1px;
            }

            &::after {
                position: absolute;
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                transition: all .8s ease;
                @include view-at(sp) {
                    opacity: .8;
                }
            }

            &:hover {
                &::after {
                    opacity: .3;
                    transform: scale(1.05);
                }
            }


            a {
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                color: white;
                text-decoration: none;
                letter-spacing: 0.1em;
                text-align: center;
                font-size: 20px;
                line-height: 1.5em;
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                opacity: 1;
                text-shadow: 0 0 10px black;
                @include view-at(sp) {
                    font-size: 16px;
                }
            }

            &:nth-child(1) {
                &::after {
                    background-image: url(../images/company/mv.jpg);
                    background-size: cover;
                    background-position: center;
                }
            }
            &:nth-child(2) {
                &::after {
                    background: url(../images/news/mv.jpg) no-repeat;
                    background-size: cover;
                    background-position: top;
                }
            }
            &:nth-child(3) {
                &::after {
                    background-image: url(../images/about/mv.jpg);
                    background-size: cover;
                    background-position: top;
                }
            }
            &:nth-child(4) {
                &::after {
                    background-image: url(../images/other/mv.jpg);
                    background-size: cover;
                    background-position: top;
                }
            }
            &:nth-child(5) {
                &::after {
                    background-image: url(../images/welfare/mv.jpg);
                    background-size: cover;
                    background-position: top;
                }
            }
            &:nth-child(6) {
                &::after {
                    background-image: url(../images/recruit/mv.jpg);
                    background-size: cover;
                    background-position: left bottom;
                }
            }
            &:nth-child(7) {
                &::after {
                    background-image: url(../images/info/mv.jpg);
                    background-size: cover;
                    background-position: top;
                }
            }
            &:nth-child(8) {
                background: $color-sub;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid white;
                    border-top: 2px solid white;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(135deg);
                    left: 48%;
                    top: 60%;
                    @include view-at(sp) {
                        top: 80%;
                    }
                }
                &:hover {
                    &::after {
                        top: 90%;
                        opacity: 1;
                    }
                }

                a {
                    text-shadow: none;
                }
            }
            
        }
    }
    .slick-slide {
        
        img {
            margin: 0 auto;
        }
    }
}



/*1.フェードインアニメーションの指定*/
.scrollanime {opacity: 0;} /*一瞬表示されるのを防ぐ*/
.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;         
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}
 
/*2.上下の動きを指定*/
.updown {transform: translateY(-100px);}
.downup {transform: translateY(100px);}
 
/*3.左右の動きを指定*/
.sect02{overflow: hidden;} /*横スクロールバーを隠す*/
.slide-right {transform: translateX(200px);}
.slide-left {transform: translateX(-200px);}
.fade-inner {
    opacity: 0;
}
.fadeInDown .fade-inner {
    animation-delay: 1.5s; 
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;         
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}