
.p-footer {
  padding: 80px 0 20px;
  color: $color-gray-dark;
  background: white;
  overflow-x: hidden;

  @include view-at(sp) {
    padding: 40px 0 20px;
  }

  &-top {
    display: flex;
    flex-wrap: wrap;
    @include view-at(sp) {
      margin: 0 -15px;
    }

    &-cta {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px;
      background: $color-gray-snow;
      letter-spacing: 0.1em;
      @include view-at(sp) {
        width: 100%;
      }
      &-tel {
        padding: 0;
        padding-bottom: 10px;
        @include view-at(sp) {
          font-size: 30px;
          padding: 0;
        }
        i {
          color: $color-main;
          @include view-at(sp) {
            padding-right: 10px;
          }
        }
      }
    }
    &-youtube {
      width: 50%;
      @include view-at(sp) {
        width: 100%;
      }
      &-wrap {
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%!important;
          height: 100%!important;
        }
      }
    }
  }
  &-logo {
    text-align: center;
    max-width: 300px;
    margin: 30px auto;

    @include view-at(sp) {
      margin: 20px auto;
      max-width: 250px;
    }
  }

  &-copyright {
    display: flex;
    justify-content: space-between;

    @include view-at(sp) {
      text-align: center;
      display: block;
    }

    a {
      text-decoration: none;
      color: $color-gray-dark;
    }
  }

  &-nav {
    margin-top: 40px;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
      @include view-at(sp) {
        margin-bottom: 40px;
      }
      > li {
        padding: 10px 20px;
        margin-bottom: 20px;
        font-size: 15px;
        width: calc(100%/ 4);
        @include view-at(sp) {
          width: 100%;
          margin-bottom: 0;
          font-size: 13px;
        }
        > a {
          color: $color-gray-dark;
          text-decoration: none;
          border-left: 5px solid $color-main;
          padding: 5px 10px;
          display: block;
          &:hover {
            opacity: 1;
          }
        }
        ol {
          list-style: none;
          padding: 10px 10px 0;
          font-size: 14px;
          @include view-at(sp) {
            font-size: 11px;
            display: flex;
            flex-wrap: wrap;
          }
          > li {
            padding-bottom: 10px;
            @include view-at(sp) {
              width: 50%;
            }
            &:nth-last-child(1) {
              padding-bottom: 0;
            }
            > a {
              color: $color-gray-dark;
              text-decoration: none;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &-pagetop {
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.1em;
    color: #ababab;
    border-radius: 0.5rem;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    padding: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    z-index: 9000;
    
     i {
      font-size: 150%;
      line-height: 60px;
      -webkit-transition: all .3s;
      transition: all .3s;
      letter-spacing: 0;
    }

    &:hover {
      i {
        line-height: 55px;
      }
    }
    @include view-at(sp) {
      width: 35px;
      height: 35px;
      font-size: 14px;
      bottom: 5px;
      right: 5px;
      i {
        line-height: 35px;
      }
      &:hover {
        i {
          line-height: 35px;
        }
      }
    }
  }
}
