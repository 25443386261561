
/* --------------------------
color
-------------------------- */
$color-main: #7ACC00;
$color-sub: #FEBD3D;

$color-btn01: #FF9DAB;
$color-btn02: #81C2F9;
$color-btn03: #BDA6F8;
$color-btn04: #FEB675;
$color-btn05: #FA6C60;
$color-btn06: #7ACC00;

$color-red: #e20013;
$color-red-dark: #D04255;
$color-red-light: #f8ebed;

$color-blue: #1ABAD5;
$color-blue-dark: #0b2b75;
$color-blue-light: #e8f5f3;

$color-gold: #dbc583;
$color-gold-light: #ede2c1;

$color-orange: #ff6c11;
$color-yellow: #FEBD3D;
$color-pink: #f8ebed;

$color-green: #009940;
$color-green-snow: #E3F0D0;
$color-green-light: #d1f2ec;

$color-white: #fff;

$color-black: #231815;

$color-gray: #ababab;
$color-gray-light: #c5c5c5;
$color-gray-dark: #717171;
$color-gray-snow: #F6F7FA;


/* --------------------------
spacing
-------------------------- */
$space: 80px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 50px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 26px, 22px, 18px, 16px, 14px, 12px;
$line-height: 68px, 30px, 30px, 22px, 20px, 18px;

$font-size-sp: 22px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 40px, 36px, 32px, 22px, 16px, 16px;

$bold: 400;
$normal: 300;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1180px;
$contents-narrow-width: 760px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);
