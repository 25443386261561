@charset "UTF-8";

.recruit {
    .page-link ul li {
        @include view-at(sp) {
            width: 100%;
        }
    }
    &-side {
        > a {
            padding: 15px;
            text-decoration: none;
            background: #FEBD3D;
            text-align: center;
            font-size: 14px;
            display: block;
            color: white;
        }

        &-list {
            margin-top: 20px;
            li {
                background: white;
                font-size: 14px;
                border: 1px solid $color-gray-light;
                margin-bottom: 10px;
                text-align: center;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-right: 2px solid $color-gray-light;
                    border-top: 2px solid $color-gray-light;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    left: 10px;
                    top: 45%;
                }
                
                a {
                    text-decoration: none;
                    padding: 15px 10px;
                    display: block;
                    line-height: 1.3em;
                }
            }
        }
    }

    &-item {
        h4 {
            line-height: 1;
            a {
                text-decoration: none;
            }
        }
        p {
            letter-spacing: 0.1em;
        }
        &-wrap {
            overflow-y: scroll;
            height: 670px;
        }
        &-tag {
            &-wrap {
                display: flex;
                align-items: center;
                @include view-at(sp) {
                    display: block;
                }
                p {
                    width: 100px;
                    @include view-at(sp) {
                        width: 100%;
                    }
                }
            }
            > a {
                padding: 8px 20px;
                border-radius: 4px;
                background: $color-yellow;
                color: white;
                display: inline-block;
                margin-right: 5px;
                font-size: 14px;
                text-decoration: none;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    margin-bottom: 10px;
                    font-size: 13px;
                }
            }
            &.new {
                a {
                    background: $color-btn01;
                }
            }
            &.midway {
                a {
                    background: $color-btn02;
                }
            }
            &.part {
                a {
                    background: $color-btn03;
                }
            }
        }
        &-text {
            p {
                font-size: 14px;
            }
        }
    }

    &-messege {
        &-img {
            position: relative;
            img {
                object-fit: cover;
                border-radius: 50%;
            }
        }
        &-name {
            position: absolute;
            bottom: 65px;
            left: -20px;
            display: inline-block;
            margin-left: 30px;
            padding: 0.8rem 2rem 0.8rem 2rem;
            background: $color-white;
            font-size: 14px;
            letter-spacing: 0.1em;
            filter: drop-shadow(0px 0px 10px #c5c5c5);
            @include view-at(sp) {
                font-size: 16px;
                bottom: 20px;
            }
            &-post {
                font-size: 14px;
                display: block;
                margin-bottom: 10px;
                @include view-at(sp) {
                    font-size: 13px;
                }
            }
            strong {
                font-size: 22px;
                font-weight: normal;
                @include view-at(sp) {
                    font-size: 18px;
                }
            }
        }
        &-text {
            display: flex;
            align-items: center;
        }
        h3 {
            border-color: $color-btn01;
            line-height: 2em;
            @include view-at(sp) {
                line-height: 1.8em;
            }
        }
    }

    &-detail {
        &-table {
            th {
                padding: 20px !important;
                background-color: #F6F7FA !important;
                border-bottom: 2px solid white !important;
            }
            td {
                font-size: 14px;
            }
        }
        &-entry {
            background: $color-sub;
            padding: 40px;
            color: white;
            text-align: center;
            letter-spacing: 0.1em;
            @include view-at(sp) {
                padding: 30px;
            }
            h4 {
                color: white;
                margin-bottom: 20px;
            }
            a {
                background: white;
                color: $color-sub;
            }
        }
        &-contact {
            background: $color-gray-snow;
            padding: 40px;
            letter-spacing: 0.1em;
            font-size: 14px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @include view-at(sp) {
                padding: 30px;
            }
            a {
                font-size: 35px;
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                text-decoration: none;
            }
        }

        .news-session-item-gaiyo {
            padding-top: 25px;
            margin-bottom: 30px;
            h4 {
                font-size: 20px;
                @include view-at(sp) {
                    font-size: 18px;
                }
            }
            p {
                font-size: 14px;
                margin-bottom: 10px;
                @include view-at(sp) {
                    font-size: 13px;
                }
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }
        .news-session-item-contact {
            margin: 0;
            p.ttl {
                font-size: 16px;
                @include view-at(sp) {
                    font-size: 14px;
                }
            }
            a.tel {
                font-size: 35px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 30px;
                }
            }
            a.c-button {
                padding: 15px;
                font-size: 14px;
                width: 300px;
                background: $color-sub;
                color: white;
                border: none;
            }
        }
    }

    &-flow {
        
        h3 {
            &.pink {
                border-color: $color-btn01;
            }
            &.blue {
                border-color: $color-btn02;
            }
            &.preple {
                border-color: $color-btn03;
            }
        }
        ul {
            width: calc(100% - 30px);
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            flex-wrap: wrap;
            @include view-at(sp) {
                width: 100%;
            }
            li {
                color: white;
                width: calc(100% / 6);
                background:  $color-btn01;
                height: 180px;
                text-align: center;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                letter-spacing: 0.05em;
                padding: 0;
                @include view-at(sp) {
                    width: calc(100% / 1);
                    height: auto;
                    padding-top: 30px;
                }

                > div {
                    line-height: 1.5;
                    padding: 15px;
                    text-align: center;
                    width: 100%;
                    @include view-at(sp) {
                        width: auto;
                    }
                    span {
                        display: block;
                        font-size: 12px;
                        margin-top: 5px;
                        line-height: 1.5em;
                        text-align: left;
                    }
                }
                
                &::before,
                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    display: block;
                    position: absolute;
                }
                &::before {
                    border: solid 160px transparent;
                    border-right: solid 33px transparent;
                    border-left: solid 33px #FFF;
                    top: -70px;
                    left: -17px;
                    z-index: 1;
                    @include view-at(sp) {
                        border: solid 240px transparent;
                        border-top: solid 33px #FFF;
                        border-bottom: solid 33px transparent;
                        top: -9px;
                        left: -65px;
                        z-index: 1;
                    }
    
                }
                &::after {
                    border: solid 140px transparent;
                    border-left: solid 30px $color-btn01;
                    border-right: solid 30px transparent;
                    top: -50px;
                    right: -35px;
                    z-index: 2;
                    @include view-at(sp) {
                        border: solid 220px transparent;
                        border-top: solid 30px #FF9DAB;
                        border-bottom: solid 30px transparent;
                        top: -13px;
                        right: -50px;
                        z-index: 2;
                    }
    
                }
                @include view-at(pc) {
                    &:not(:first-child):not(:last-child) { 
                        padding: 0 10px;
                    }
                    &:first-child::before,
                    &:last-child::after {
                        content: none;
                    }
                }
                
                @include view-at(sp) {
                    &:nth-child(1) {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        &::before,
                        &::after {
                            content: none;
                        }
                    }
                    &:nth-last-child(1) {
                        padding-bottom: 10px;
                    }
                }
            }
            &.blue {
                li {
                    background: $color-btn02;
                    &::after {
                        border-left: solid 30px $color-btn02;
                        @include view-at(sp) {
                            border: solid 220px transparent;
                            border-top: solid 30px $color-btn02;
                            border-bottom: solid 30px transparent;
                        }
                    }
                }
            }
            &.preple {
                li {
                    background: $color-btn03;
                    &::after {
                        border-left: solid 30px $color-btn03;
                        @include view-at(sp) {
                            border: solid 220px transparent;
                            border-top: solid 30px $color-btn03;
                            border-bottom: solid 30px transparent;
                        }
                    }
                }
            }
        }
        &-wrap {
            margin-right: -30px;
            @include view-at(sp) {
                margin-right: 0;
            }
        }
    }

    &-new {
        &-item {
            display: block;
            text-decoration: none;
            &-icon {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 14px;
                background: $color-btn02;
                color: white;
                padding: 8px 15px;
                &.part {
                    background: $color-btn03;
                }
            }
        }
        &-text {
            position: relative;
            @include view-at(sp) {
                padding-bottom: 30px;
            }
        }
        &-tag {
            margin-bottom: 10px;
            span {
                border: 1px solid $color-main;
                padding: 3px 10px;
                font-size: 12px;
                border-radius: 5px;
                line-height: 1;
                @include view-at(sp) {
                    padding: 3px 10px;
                    font-size: 10px;
                }
            }
        }
        &-link {
            position: absolute;
            right: 60px;
            bottom: 20px;
            font-size: 12px;
            text-decoration: none;
            line-height: 1;
            @include view-at(sp) {
                right: 45px;
                bottom: 5px;
                font-size: 10px;
            }
            &::before {
                content: "";
                width: 30px;
                height: 1px;
                background: #ababab;
                position: absolute;
                top: 8px;
                right: -40px;
            }
            &::after {
                content: "";
                width: 10px;
                height: 1px;
                background: #ababab;
                position: absolute;
                top: 5px;
                right: -41px;
                transform: rotate(45deg);
            }
        }
    }
}