/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(sp) {
      position: absolute;
      right: 12px;
      top: 14px;
      display: block;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
  }

  &-trigger {
    position: relative;
    width: 28px;
    height: 23px;

    &-label {
      display: block;
      font-size: 8px;
      letter-spacing: 1.1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-white;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color-sub;
  }

  &-trigger span:nth-of-type(1) {
    top: 0;
  }

  &-trigger span:nth-of-type(2) {
    top: 10px;
  }

  &-trigger span:nth-of-type(3) {
    bottom: 0;
  }

  &-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY(10px) translateX(1px) rotate(-45deg);
    transform: translateY(10px) translateX(1px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(-10px) translateX(1px) rotate(45deg);
    transform: translateY(-10px) translateX(1px) rotate(45deg);
  }

  &-recruit {
    right: 20px;
  }
}



