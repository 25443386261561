@charset "UTF-8";
.other {
    &-us {
        &-img {
            position: relative;
            margin-bottom: 10px;
            @include view-at(sp) {
                padding: 15px 30px 0;
                margin-bottom: 5px;
            }
            img {
                object-fit: cover;
                border-radius: 50%;
            }
        }
        &-name {
            position: absolute;
            bottom: 10px;
            left: -20px;
            display: inline-block;
            height: 50px;
            margin-left: 30px;
            padding: 0.9rem 2rem 1rem 3rem;
            color: white;
            background: $color-btn01;
            font-size: 20px;
            letter-spacing: 0.1em;
            transform: rotate(-5deg);
            @include view-at(sp) {
                left: -15px;
                height: 40px;
                padding: 10px 30px 0;
                font-size: 18px;
                transform: rotate(-3deg);
                margin-bottom: 5px;
            }
            &::before {
                position: absolute;
                top: 0;
                left: -22px;
                content: '';
                border-width: 25px 23px 25px 0;
                border-style: solid;
                border-color: transparent $color-btn01 transparent transparent;
                @include view-at(sp) {
                    left: -17px;
                    border-width: 20px 18px 20px 0;
                }
            }
            &::after {
                position: absolute;
                top: calc(50% - 7px);
                left: -8px;
                width: 12px;
                height: 12px;
                content: '';
                border-radius: 50%;
                background: #fff;
                @include view-at(sp) {
                    top: calc(50% - 5px);
                    left: -3px;
                    width: 8px;
                    height: 8px;
                }
                
            }
            &-post {
                font-size: 14px;
                @include view-at(sp) {
                    font-size: 13px;
                }
            }
            &-kana {
                font-size: 12px;
                display: block;
                margin-top: 5px;
                text-align: center;
                @include view-at(sp) {
                    font-size: 11px;
                }
            }

            &.blue {
                background: $color-btn02;
                &::before {
                    border-color: transparent $color-btn02 transparent transparent;
                }
            }
            &.purple {
                background: $color-btn03;
                &::before {
                    border-color: transparent $color-btn03 transparent transparent;
                }
            }
            &.orange {
                background: $color-btn04;
                &::before {
                    border-color: transparent $color-btn04 transparent transparent;
                }
            }
            &.red {
                background: $color-btn05;
                &::before {
                    border-color: transparent $color-btn05 transparent transparent;
                }
            }
        }
        &-history {
            line-height: 1.3em;
            letter-spacing: 0.05em;
            text-align: center;
        }
        h3 {
            border-color: $color-btn01;
            line-height: 2em;
            @include view-at(sp) {
                line-height: 1.8em;
            }
        }

        &-messege {
            margin: 1rem -10px;
            padding: 0.5rem 2rem;
            background: $color-btn01;
            border: none;
            position: absolute;
            top: 0px;
            left: 0;
            font-size: 16px;
            padding-right: 50px;
            color: white;
            letter-spacing: 0.05em;
            @include view-at(sp) {
                font-size: 13px;
                line-height: 1.5em !important;
                letter-spacing: 0;
                padding: 0.8rem 2.5rem;
                padding-left: 15px;
            }

            &::before {
                position: absolute;
                content: '';
                bottom: -10px;
                left: 0;
                width: 0;
                height: 0;
                border-top: 10px solid $color-btn01;
                border-left: 10px solid transparent;
            }
            &::after {
                position: absolute;
                content: '';
                right: -30px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 50px solid white;
                border-right: 50px solid transparent;
                transform: rotate( -45deg);
                @include view-at(sp) {
                    top: -5%;
                }
            }

            &.blue {
                background: $color-btn02;
                &::before {
                    border-top: 10px solid $color-btn02;
                }
            }
            &.purple {
                background: $color-btn03;
                @include view-at(sp) {
                    padding-right: 60px;
                }
                &::before {
                    border-top: 10px solid $color-btn03;
                }
                &::after {
                    @include view-at(sp) {
                        top: -4%;
                        right: -50px;
                        border-width: 70px;
                    }
                }
            }
            &.orange {
                background: $color-btn04;
                &::before {
                    border-top: 10px solid $color-btn04;
                }
            }
            &.red {
                background: $color-btn05;
                &::before {
                    border-top: 10px solid $color-btn05;
                }
            }
        }

        .slick {
            margin-bottom: 15px;
            @include view-at(sp) {
                margin: 0 30px 15px;
            }
            .slick-dots {
                bottom: -20px;
                li {
                    height: 5px;
                    width: 30px;
                    button {
                        &::before {
                            content: "";
                            height: 5px;
                            width: 30px;
                            background: $color-btn01;
                        }
                    }
                }
            }
            &.blue {
                .slick-dots li button::before {
                    background: $color-btn02;
                }
            }
            &.purple {
                .slick-dots li button::before {
                    background: $color-btn03;
                }
            }
            &.orange {
                .slick-dots li button::before {
                    background: $color-btn04;
                }
            }
            &.red {
                .slick-dots li button::before {
                    background: $color-btn05;
                }
            }
        }
        
    }

    &-faq {
        &-list {
            margin-bottom: 30px;
            letter-spacing: 0.1em;
            @include view-at(sp) {
                margin-bottom: 20px;
            }
            dt {
                background: $color-green-snow;
                padding: 15px;
                @include view-at(sp) {
                    font-size: 14px;
                    line-height: 1.5em;
                    position: relative;
                    padding-right: 25px;
                    letter-spacing: 0.05em;

                    &::after {
                        content: "+";
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        font-size: 25px;
                        color: #c5c5c5;
                    }
                }
            }
            dd {
                padding: 10px 15px;
                font-size: 14px;
                line-height: 1.5em;
                text-align: justify;
                text-justify: inter-ideograph;
                @include view-at(sp) {
                    font-size: 13px;
                    line-height: 1.5em;
                    padding: 10px 0;
                    display: none;
                }
            }
        }
    }
}