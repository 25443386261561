/* --------------------------
テキスト装飾
-------------------------- */
.u-text {
  line-height: 2em;
}
.u-text-accent {
  color: $color-blue-dark;
}

.u-text-important {
  color: $color-sub;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-pink {
  color: $color-btn01;
  &::after {
    background-color: $color-btn01;
  }
}
.u-text-blue {
  color: $color-btn02;
  &::after {
    background-color: $color-btn02;
  }
}
.u-text-purple {
  color: $color-btn03;
  &::after {
    background-color: $color-btn03;
  }
}
.u-text-orange {
  color: $color-btn04;
  &::after {
    background-color: $color-btn04;
  }
}
.u-text-red {
  color: $color-btn05;
  &::after {
    background-color: $color-btn05;
  }
}
.u-text-sky {
  color: $color-btn03;
  &::after {
    background-color: $color-btn03;
  }
}
.u-text-perple {
  color: $color-btn04;
  &::after {
    background-color: $color-btn04;
  }
}
.u-text-serif {
  font-family: 'Times New Roman', Times, serif;
}
.u-text-messege {
  font-family: 'Kiwi Maru', serif !important;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  letter-spacing: 0.06em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
  }
}

.u-text-large {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.05em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.u-text-default {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.u-text-small {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }

  &--sp {
    @include view-at(sp) {
      font-size: nth($font-size-sp, 6);
      line-height: nth($line-height-sp,6);
    }
  }
}