
.p-gnav {
  @include view-at(sp) {
    display: none;
    height: calc(100vh - 50px);
    overflow: scroll;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: $contents-width;
    margin: 0 auto;
    padding: 10px $contents-padding 0;
    transition: all .3s ease-in-out;
    background-color: $color-white;

    @include view-at(sp) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
      padding: 0;
      border-top: 1px solid $color-gray-light;
    }

    &-item {
      width: calc(100% /8);
      @media all and (-ms-high-contrast: none) {
        width: 12.5%;
      }
      position: relative;
      border-left: 1px solid $color-gray-light;
      height: 62px;
      
      @include view-at(tab) {
        padding: 10px;
      }

      @include view-at(sp) {
        padding: 0;
        border: none;
        border-bottom: 1px solid $color-gray-light;
        width: 100%;
        height: auto;
      }

      &:nth-last-child(1) {
        border-right: 1px solid $color-gray-light;
        @include view-at(sp) {
          border-right: none;
        }
      }

      &.current {
        > a {
          border-bottom: 5px solid $color-sub;
          @include view-at(sp) {
            border-bottom: none;
          }
        }
      }
      
      > a {
        font-size: 13px;
        letter-spacing: 0.05em;
        color: $color-gray-dark;
        text-decoration: none;
        line-height: 1.5;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        &:hover {
          border-bottom: 5px solid $color-sub;
          opacity: 1;
        }
        @include view-at(sp) {
          padding: 15px 20px;
          text-align: left;
          justify-content: end;

          &.nav-open {
            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 8px;
              height: 8px;
              border-right: 2px solid $color-gray-light;
              border-top: 2px solid $color-gray-light;
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              right: 20px;
              top: 20px;
            }
          }
          &:hover {
            border-bottom: none;
          }
        }
      }

      ol {
        display: none;
        list-style: none;
        position: absolute;
        width: 100%;
        top: 62px;
        left: 0;
        background: $color-main;
        @include view-at(sp) {
          position: unset;
          flex-wrap: wrap;
          padding-left: 20px;
        }

        li {
          border-bottom: 1px solid $color-white;
          &:nth-last-child(1) {
            border-bottom: none;
          }
          @include view-at(sp) {
            border: none;
          }
          > a {
            font-size: 13px;
            line-height: 1.3em;
            letter-spacing: 0.05em;
            padding: 10px;
            display: block;
            text-align: left;
            color: white;
            text-decoration: none;
            @include view-at(sp) {
              position: relative;
              padding-left: 20px;
              display: block;
  
              &::before {
                content: "";
                display:block;
                position:absolute;
                width:6px;
                height:6px;
                border-right:2px solid  $color-white;
                border-top:2px solid  $color-white;
                -webkit-transform:rotate(45deg);
                transform:rotate(45deg);
                top: 18px;
                left: 8px;
                color: white;
              }
            }
          }
        }
      }

      &:hover {
        ol {
          @include view-at(sp) {
            display: none;
          }
          &.active {
            @include view-at(sp) {
              display: flex !important;
            }
          }
        }
      }
    }
  }
}
