
body {
  font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-gray-dark;
  letter-spacing: 0.05em;
}

a {
  color: $color-gray-dark;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

p {
  font-size: nth($font-size, 4);
  line-height: 1.8em;
  margin-bottom: 20px;
  text-align: justify;
  text-justify: inter-ideograph;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 140px;
  @include view-at(sp) {
    margin-left: 0;
    padding-top: 50px;
  }
}

.page{
  &-mv {
    background: $color-gray-snow;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include view-at(sp) {
      height: 200px;
    }
    &-text {
      text-align: center;
      font-size: 25px;
      letter-spacing: 0.1em;
      @include view-at(sp) {
        line-height: 1.5em;
        font-size: 18px;
      }
    }

    &.info {
      background-image: url(../images/info/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.recruit {
      background-image: url(../images/recruit/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.other {
      background-image: url(../images/other/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.aboutus {
      background-image: url(../images/other/mv02.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.aboutus02 {
      background-image: url(../images/other/mv01.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.about {
      background-image: url(../images/about/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.welfare {
      background-image: url(../images/welfare/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.news {
      background: url(../images/news/mv.jpg) no-repeat;
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
    &.company {
      background-image: url(../images/company/mv.jpg);
      background-size: cover;
      background-position: center;
      .page-mv-text {
        text-shadow: black 0px 0px 8px, black 0px 0px 8px;
        color: white;
      }
    }
  }
  &-link {
    margin-top: 40px !important;
    @include view-at(sp) {
      margin: 30px 0 !important;
    }
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: -5px;
      @include view-at(sp) {
        margin: -3px;
      }

      li {
        padding: 5px;
        width: 20%;
        
        @include view-at(sp) {
          width: 50%;
          padding: 3px;
        }
        a {
          text-decoration: none;
          display: block;
          padding: 20px 10px;
          background: white;
          text-align: center;
          border: 2px solid $color-btn01;
          letter-spacing: 0.05em;
          position: relative;
          font-size: 13px;
          @include view-at(sp) {
            padding: 15px 10px;
            font-size: 12px;
          }
          
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            border-right: 2px solid $color-btn01;
            border-top: 2px solid $color-btn01;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            right: 10px;
            top: 45%;
            @include view-at(sp) {
            }
          }
          &:hover {
            color: white;
            background: $color-btn01;
            opacity: 1;
            &::after {
              border-color: white;
            }
          }
        }
        
        &:nth-child(2) {
          a {
            border: 2px solid $color-btn02;
            &::after {
              border-color: $color-btn02;
            }
            &:hover {
              background: $color-btn02;
              &::after {
                border-color: white;
              }
            }
          }
        }
        &:nth-child(3) {
          a {
            border: 2px solid $color-btn03;
            &::after {
              border-color: $color-btn03;
            }
            &:hover {
              background: $color-btn03;
              &::after {
                border-color: white;
              }
            }
          }
        }
        &:nth-child(4) {
          a {
            border: 2px solid $color-btn04;
            &::after {
              border-color: $color-btn04;
            }
            &:hover {
              background: $color-btn04;
              &::after {
                border-color: white;
              }
            }
          }
        }
        &:nth-child(5) {
          a {
            border: 2px solid $color-btn05;
            &::after {
              border-color: $color-btn05;
            }
            &:hover {
              background: $color-btn05;
              &::after {
                border-color: white;
              }
            }
          }
        }
        &.orange {
          a {
            border: 2px solid $color-btn04;
            &::after {
              border-color: $color-btn04;
            }
            &:hover {
              background: $color-btn04;
              &::after {
                border-color: white;
              }
            }
          }
        }
        &.red {
          a {
            border: 2px solid $color-btn05;
            &::after {
              border-color: $color-btn05;
            }
            &:hover {
              background: $color-btn05;
              &::after {
                border-color: white;
              }
            }
          }
        }
      }

      .spacer {
        width: 100%;
        @include view-at(sp) {
          display: none;
        }
      }
    }

    &-panel {
     
      ul {
        display: grid;
        display: -ms-grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        -ms-grid-columns:1fr 1fr 1fr 1fr;
        -ms-grid-rows: 1fr;
        height: 200px;
        @include view-at(sp) {
          grid-template-rows:1.5fr 1fr;
        }
        li {
          grid-column: 1 / 3;
          grid-row: 1/2;
          -ms-grid-column: 1;
          -ms-grid-row: 1;
          -ms-grid-column-span: 2;
          margin: 5px;
          background-image: url(../images/recruit/panel01.jpg);
          background-size: cover;
          background-position: center;
          @include view-at(sp) {
            margin: 1px;
          }
          &:nth-child(1) {
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            @include view-at(sp) {
              grid-row: 1/2;
              grid-column: 1 / 3;
            }
          }
          &:nth-child(2) {
            grid-column: 3/4;
            grid-row: 1;
            -ms-grid-column: 2;
            -ms-grid-row: 1;
            background-image: url(../images/recruit/panel02.jpg);
            background-size: cover;
            background-position: center;
            @include view-at(sp) {
              grid-row: 2/3;
              grid-column: 1 / 2;
            }
          }
          &:nth-child(3) {
            grid-column: 4/5;
            grid-row: 1;
            -ms-grid-column: 2;
            -ms-grid-row: 2;
            background-image: url(../images/recruit/panel03.jpg);
            background-size: cover;
            background-position: center;
            @include view-at(sp) {
              grid-row: 2/3;
            }
          }
          // &:nth-child(4) {
          //   grid-column: 1/2;
          //   grid-row: 3/4;
          //   -ms-grid-column: 1;
          //   -ms-grid-row: 3;
          //   background: $color-btn04;
          //   @include view-at(sp) {
          //     grid-column: 1/3;
          //   }
          // }
          // &:nth-child(5) {
          //   grid-column: 2/3;
          //   grid-row: 3/4;
          //   -ms-grid-column: 2;
          //   -ms-grid-row:3;
          //   background: $color-btn05;
          //   @include view-at(sp) {
          //     grid-column: 1/3;
          //     grid-row: 4/5;
          //   }
          // }

          a {
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: white;
            text-decoration: none;
            letter-spacing: 0.1em;
          }
        }
      }
    }
  }
}

.ancer {
  padding-top: 150px !important;
  margin-top: -150px !important;
  @include view-at(sp) {
    padding-top: 80px !important;
    margin-top: -80px !important;
  }
}

.pager {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  li {
    padding: 5px;

    a {
      width: 30px;
      height: 30px;
      color: white;
      border-radius: 50%;
      display: block;
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-gray-light;
      font-family: 'Montserrat', sans-serif;
      &:hover {
        background: $color-main;
      }
    }
    &.current {
      a {
        background: $color-main;
      }
    }
  }

  &-sub {
    display: flex;
    justify-content: center;
    @include view-at(sp) {
      flex-wrap: wrap;
      margin: 0 -5px;
    }
    li {
      padding: 10px;
      @include view-at(sp) {
        width: 50%;
        padding: 5px;
      }
      a {
        border-radius: 40px;
        background: #c5c5c5;
        color: white;
        @include view-at(sp) {
          width: 100%;
          padding: 15px;
          font-size: 13px;
        }
        &::after {
          border-color: white;
        }
        &:hover {
          background: $color-main;
        }
      }
    }
  }
}