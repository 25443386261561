@charset "UTF-8";
/* --------------------------
color
-------------------------- */
/* --------------------------
spacing
-------------------------- */
/* --------------------------
font
-------------------------- */
/* --------------------------
contents width
-------------------------- */
/* --------------------------
z-index order
-------------------------- */
/* --------------------------
media query
-------------------------- */
/* --------------------------
h with center-line
-------------------------- */
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=21750147-d652-4704-9a95-8b5f2d411f76");
@font-face {
  font-family: "FrutigerNeueW01-Regular";
  src: url("../../fonts/e2e74e1c-9e18-4127-adfd-feb8f6a9ceaf.eot?#iefix");
  src: url("../../fonts/e2e74e1c-9e18-4127-adfd-feb8f6a9ceaf.eot?#iefix") format("eot"), url("../../fonts/9edf3443-80c6-4de6-b582-122401f63ad4.woff2") format("woff2"), url("../../fonts/aa94077e-cdcd-4e33-abe0-ddcfe27e3485.woff") format("woff"), url("../../fonts/22dfb43f-26df-46f9-aa0f-30038f8da834.ttf") format("truetype"), url("../../fonts/36ad2de1-95ea-4eb3-a836-29c86179a248.svg#36ad2de1-95ea-4eb3-a836-29c86179a248") format("svg");
}

@font-face {
  font-family: "Frutiger Neue W01 Md";
  src: url("../../fonts/979450a2-8d59-4cc2-9937-94255a4f3923.eot?#iefix");
  src: url("../../fonts/979450a2-8d59-4cc2-9937-94255a4f3923.eot?#iefix") format("eot"), url("../../fonts/1f198a6d-f4fe-44f1-b78c-1b6a1cf66d00.woff2") format("woff2"), url("../../fonts/4748e3d8-1b23-420a-a419-d7a367ea1076.woff") format("woff"), url("../../fonts/11f76f62-1d4f-4327-bce7-1eee2aba08bf.ttf") format("truetype"), url("../../fonts/ce4a6e96-f298-4ab1-a2ac-bdbbbc1467d6.svg#ce4a6e96-f298-4ab1-a2ac-bdbbbc1467d6") format("svg");
}

@font-face {
  font-family: "Frutiger Neue W01 Bd";
  src: url("../../fonts/62057895-1f9e-4d70-bd1a-65751a20a878.eot?#iefix");
  src: url("../../fonts/62057895-1f9e-4d70-bd1a-65751a20a878.eot?#iefix") format("eot"), url("../../fonts/26f1236c-a914-477f-9d0f-10d758702626.woff2") format("woff2"), url("../../fonts/7875f3d5-b62b-445c-8c9e-d65da1514e9d.woff") format("woff"), url("../../fonts/1c242f4e-0c64-47cc-8cbc-e53d949280ba.ttf") format("truetype"), url("../../fonts/ec132df9-012a-4594-bbd9-c86574bee7b0.svg#ec132df9-012a-4594-bbd9-c86574bee7b0") format("svg");
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 300;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

body {
  font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: #717171;
  letter-spacing: 0.05em;
}

a {
  color: #717171;
  transition: all .3s ease-in-out;
}

a:hover {
  opacity: 0.6;
}

p {
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 20px;
  text-align: justify;
  text-justify: inter-ideograph;
}

p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 140px;
}

@media screen and (max-width: 768px) {
  .main {
    margin-left: 0;
    padding-top: 50px;
  }
}

.page-mv {
  background: #F6F7FA;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .page-mv {
    height: 200px;
  }
}

.page-mv-text {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .page-mv-text {
    line-height: 1.5em;
    font-size: 18px;
  }
}

.page-mv.info {
  background-image: url(../images/info/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.info .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.recruit {
  background-image: url(../images/recruit/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.recruit .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.other {
  background-image: url(../images/other/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.other .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.aboutus {
  background-image: url(../images/other/mv02.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.aboutus .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.aboutus02 {
  background-image: url(../images/other/mv01.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.aboutus02 .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.about {
  background-image: url(../images/about/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.about .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.welfare {
  background-image: url(../images/welfare/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.welfare .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.news {
  background: url(../images/news/mv.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.page-mv.news .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-mv.company {
  background-image: url(../images/company/mv.jpg);
  background-size: cover;
  background-position: center;
}

.page-mv.company .page-mv-text {
  text-shadow: black 0px 0px 8px, black 0px 0px 8px;
  color: white;
}

.page-link {
  margin-top: 40px !important;
}

@media screen and (max-width: 768px) {
  .page-link {
    margin: 30px 0 !important;
  }
}

.page-link ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px;
}

@media screen and (max-width: 768px) {
  .page-link ul {
    margin: -3px;
  }
}

.page-link ul li {
  padding: 5px;
  width: 20%;
}

@media screen and (max-width: 768px) {
  .page-link ul li {
    width: 50%;
    padding: 3px;
  }
}

.page-link ul li a {
  text-decoration: none;
  display: block;
  padding: 20px 10px;
  background: white;
  text-align: center;
  border: 2px solid #FF9DAB;
  letter-spacing: 0.05em;
  position: relative;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .page-link ul li a {
    padding: 15px 10px;
    font-size: 12px;
  }
}

.page-link ul li a::after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid #FF9DAB;
  border-top: 2px solid #FF9DAB;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 10px;
  top: 45%;
}

.page-link ul li a:hover {
  color: white;
  background: #FF9DAB;
  opacity: 1;
}

.page-link ul li a:hover::after {
  border-color: white;
}

.page-link ul li:nth-child(2) a {
  border: 2px solid #81C2F9;
}

.page-link ul li:nth-child(2) a::after {
  border-color: #81C2F9;
}

.page-link ul li:nth-child(2) a:hover {
  background: #81C2F9;
}

.page-link ul li:nth-child(2) a:hover::after {
  border-color: white;
}

.page-link ul li:nth-child(3) a {
  border: 2px solid #BDA6F8;
}

.page-link ul li:nth-child(3) a::after {
  border-color: #BDA6F8;
}

.page-link ul li:nth-child(3) a:hover {
  background: #BDA6F8;
}

.page-link ul li:nth-child(3) a:hover::after {
  border-color: white;
}

.page-link ul li:nth-child(4) a {
  border: 2px solid #FEB675;
}

.page-link ul li:nth-child(4) a::after {
  border-color: #FEB675;
}

.page-link ul li:nth-child(4) a:hover {
  background: #FEB675;
}

.page-link ul li:nth-child(4) a:hover::after {
  border-color: white;
}

.page-link ul li:nth-child(5) a {
  border: 2px solid #FA6C60;
}

.page-link ul li:nth-child(5) a::after {
  border-color: #FA6C60;
}

.page-link ul li:nth-child(5) a:hover {
  background: #FA6C60;
}

.page-link ul li:nth-child(5) a:hover::after {
  border-color: white;
}

.page-link ul li.orange a {
  border: 2px solid #FEB675;
}

.page-link ul li.orange a::after {
  border-color: #FEB675;
}

.page-link ul li.orange a:hover {
  background: #FEB675;
}

.page-link ul li.orange a:hover::after {
  border-color: white;
}

.page-link ul li.red a {
  border: 2px solid #FA6C60;
}

.page-link ul li.red a::after {
  border-color: #FA6C60;
}

.page-link ul li.red a:hover {
  background: #FA6C60;
}

.page-link ul li.red a:hover::after {
  border-color: white;
}

.page-link ul .spacer {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .page-link ul .spacer {
    display: none;
  }
}

.page-link-panel ul {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .page-link-panel ul {
    grid-template-rows: 1.5fr 1fr;
  }
}

.page-link-panel ul li {
  grid-column: 1 / 3;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column-span: 2;
  margin: 5px;
  background-image: url(../images/recruit/panel01.jpg);
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .page-link-panel ul li {
    margin: 1px;
  }
}

.page-link-panel ul li:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
}

@media screen and (max-width: 768px) {
  .page-link-panel ul li:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1 / 3;
  }
}

.page-link-panel ul li:nth-child(2) {
  grid-column: 3/4;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  background-image: url(../images/recruit/panel02.jpg);
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .page-link-panel ul li:nth-child(2) {
    grid-row: 2/3;
    grid-column: 1 / 2;
  }
}

.page-link-panel ul li:nth-child(3) {
  grid-column: 4/5;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  background-image: url(../images/recruit/panel03.jpg);
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .page-link-panel ul li:nth-child(3) {
    grid-row: 2/3;
  }
}

.page-link-panel ul li a {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-decoration: none;
  letter-spacing: 0.1em;
}

.ancer {
  padding-top: 150px !important;
  margin-top: -150px !important;
}

@media screen and (max-width: 768px) {
  .ancer {
    padding-top: 80px !important;
    margin-top: -80px !important;
  }
}

.pager {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pager li {
  padding: 5px;
}

.pager li a {
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c5c5c5;
  font-family: 'Montserrat', sans-serif;
}

.pager li a:hover {
  background: #7ACC00;
}

.pager li.current a {
  background: #7ACC00;
}

.pager-sub {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .pager-sub {
    flex-wrap: wrap;
    margin: 0 -5px;
  }
}

.pager-sub li {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .pager-sub li {
    width: 50%;
    padding: 5px;
  }
}

.pager-sub li a {
  border-radius: 40px;
  background: #c5c5c5;
  color: white;
}

@media screen and (max-width: 768px) {
  .pager-sub li a {
    width: 100%;
    padding: 15px;
    font-size: 13px;
  }
}

.pager-sub li a::after {
  border-color: white;
}

.pager-sub li a:hover {
  background: #7ACC00;
}

.entry-content h1 {
  font-size: 30px;
  line-height: 1.5em;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .entry-content h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.entry-content h2 {
  font-size: 26px;
  line-height: 1.5em;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .entry-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.entry-content h3 {
  font-size: 22px;
  line-height: 1.5em;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .entry-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.entry-content h4 {
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .entry-content h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.entry-content h5 {
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .entry-content h5 {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.entry-content h6 {
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .entry-content h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.entry-content .wp-block-gallery > li {
  padding-left: 0;
}

.entry-content .wp-block-gallery > li::before {
  display: none;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
  margin: 40px 0;
}

.wp-block-quote {
  padding: 20px;
  background-color: #F6F7FA;
}

.wp-block-image::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

.wp-block-table.aligncenter, .wp-block-table.alignleft, .wp-block-table.alignright {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .wp-block-column + .wp-block-column {
    margin-top: 40px;
  }
}

.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.wp-pagenavi a {
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c5c5c5;
  font-family: 'Montserrat', sans-serif;
  border: none;
}

.wp-pagenavi a:hover {
  background: #7ACC00;
}

.wp-pagenavi span.current {
  background: #7ACC00;
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  border: none;
}

#breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
  font-size: 13px;
}

#breadcrumbs a {
  padding-right: 30px;
  position: relative;
  text-decoration: none;
  padding: 0 5px;
}

#breadcrumbs span.breadcrumb_last {
  padding-left: 5px;
}

.wp-page-navigation a {
  border-radius: 40px;
  background: #c5c5c5;
  color: white;
  letter-spacing: 0.1em;
  border: 1px solid #c5c5c5;
  width: 300px;
  display: inline-block;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  position: relative;
  font-size: 14px;
  margin: 2px;
}

.wp-page-navigation a::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #ababab;
  border-top: 2px solid #ababab;
  transform: rotate(45deg);
  right: 14px;
  top: 45%;
  border-color: white;
}

.wp-page-navigation a:nth-child(1)::after {
  right: auto;
  left: 14px;
  transform: rotate(-135deg);
}

.wp-page-navigation a:hover {
  background: #7ACC00;
}

/* --------------------------
パンくずナビ
-------------------------- */
.c-breadcrumb-list {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
}

.c-breadcrumb-list li {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 30px;
  font-size: 13px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .c-breadcrumb-list li {
    font-size: 11px;
  }
}

.c-breadcrumb-list li::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #717171;
  border-top: 2px solid #717171;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 5px;
  right: 15px;
}

.c-breadcrumb-list li:last-child::after {
  content: none;
}

/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ababab;
  position: relative;
  font-size: 14px;
}

.c-button:hover {
  opacity: 0.7;
  text-decoration: none;
}

.c-button--arrow {
  letter-spacing: 0.1em;
  border: 1px solid #c5c5c5;
  width: 300px;
}

.c-button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #ababab;
  border-top: 2px solid #ababab;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 14px;
  top: 45%;
}

.c-button--arrow-left {
  letter-spacing: 0.1em;
  border: 1px solid #c5c5c5;
  width: 300px;
}

.c-button--arrow-left::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #ababab;
  border-top: 2px solid #ababab;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  left: 14px;
  top: 45%;
}

.c-button--primary {
  background-color: #FEBD3D;
  border: 0;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .c-button--primary {
    padding: 15px;
    width: 100%;
  }
}

.c-button-more {
  position: absolute;
  top: 15px;
  right: 0;
}

/* --------------------------
フォーム
-------------------------- */
.c-form-text {
  border: 1px solid #c5c5c5;
  width: 100%;
  padding: 3px 15px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
}

.c-form-textarea {
  border: 1px solid #c5c5c5;
  width: 100%;
  padding: 8px 5px;
  height: 100px;
  border-radius: 3px;
}

.c-form-checkbox-input {
  display: none;
}

.c-form-checkbox-input:checked + .c-form-checkbox-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 12px;
  transform: rotate(40deg);
  border-bottom: 3px solid #e20013;
  border-right: 3px solid #e20013;
}

.c-form-checkbox-span {
  padding-left: 28px;
  position: relative;
}

.c-form-checkbox-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #ababab;
  background-color: #fff;
}

.c-form-radio-input {
  display: none;
}

.c-form-radio-input:checked + .c-form-radio-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e20013;
}

.c-form-radio-span {
  padding-left: 28px;
  position: relative;
}

.c-form-radio-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #c5c5c5;
  background-color: #fff;
}

/* --------------------------
見出し
-------------------------- */
.c-h1 {
  margin-top: 190px;
  color: #231815;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: 26px;
  line-height: 68px;
}

@media screen and (max-width: 768px) {
  .c-h1 {
    font-size: 22px;
    line-height: 40px;
    padding: 15px;
    margin-top: 110px;
  }
}

.c-h1 small {
  display: block;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 3px;
}

.c-h2 {
  text-align: center;
  margin: 0 auto 40px;
  font-weight: 300;
  font-size: 26px;
  line-height: 1.5;
  letter-spacing: 0.15em;
  text-indent: 0.2em;
}

@media screen and (max-width: 768px) {
  .c-h2 {
    font-size: 20px;
    margin: 0 auto 20px;
  }
}

.c-h2 span {
  display: block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.15em;
  font-size: 18px;
  margin-bottom: 5px;
  color: #7ACC00;
}

@media screen and (max-width: 768px) {
  .c-h2 span {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 10px;
  }
}

.c-h2.pink span {
  color: #FF9DAB;
}

.c-h2.blue span {
  color: #81C2F9;
}

.c-h2.purple span {
  color: #BDA6F8;
}

.c-h2.orange span {
  color: #FEB675;
}

.c-h2.red span {
  color: #FA6C60;
}

.c-h3 {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.08em;
  font-weight: 300;
  margin-bottom: 30px;
  border-left: 5px solid #7ACC00;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .c-h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}

.c-h3.blue {
  border-left: 5px solid #81C2F9;
}

.c-h3.purple {
  border-left: 5px solid #BDA6F8;
}

.c-h3.orange {
  border-left: 5px solid #FEB675;
}

.c-h3.red {
  border-left: 5px solid #FA6C60;
}

.c-h4 {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .c-h4 {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

.c-h5 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .c-h5 {
    font-size: 16px;
    line-height: 22px;
  }
}

.c-h6 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .c-h6 {
    font-size: 12px;
    line-height: 16px;
  }
}

.c-h--center-line {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.08em;
  font-weight: 300;
  margin-bottom: 30px;
}

.c-h--center-line::before, .c-h--center-line::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #c5c5c5;
  display: block;
}

.c-h--center-line::before {
  margin-right: 30px;
}

@media screen and (max-width: 768px) {
  .c-h--center-line::before {
    margin-right: 15px;
  }
}

.c-h--center-line::after {
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .c-h--center-line::after {
    margin-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-h--center-line {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}

.c-h--center-line.pink {
  color: #FF9DAB;
}

.c-h--center-line.blue {
  color: #81C2F9;
}

/* --------------------------
ラベル
-------------------------- */
.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: #c5c5c5;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
}

.c-label--blue-dark {
  background-color: #0b2b75;
}

.c-label--red {
  background-color: #e20013;
}

.c-label--blue {
  background-color: #1ABAD5;
}

.c-label--green {
  background-color: #009940;
}

.c-label--large {
  padding: 6px 12px;
  font-size: 16px;
}

.c-label--small {
  padding: 5px 8px;
  font-size: 12px;
}

/* --------------------------
リンク
-------------------------- */
.c-link {
  color: #1ABAD5;
}

.c-link--block {
  display: block;
  text-decoration: none;
}

.c-link--block img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-link--none {
  text-decoration: none;
}

/* --------------------------
リスト
-------------------------- */
.c-list {
  font-size: 16px;
}

.c-list > li {
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.c-list > li:last-child {
  margin-bottom: 0;
}

.c-list--point, .entry-content ul {
  margin-bottom: 1em;
}

.c-list--point > li, .entry-content ul > li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 32px;
  position: relative;
}

.c-list--point > li::before, .entry-content ul > li::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #231815;
  position: absolute;
  top: 14px;
  left: 0;
}

.c-list--point > li:last-child, .entry-content ul > li:last-child {
  margin-bottom: 0;
}

.c-list--link li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.c-list--link li::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6.9px;
  border-color: transparent transparent transparent #e20013;
  position: absolute;
  top: 8px;
  left: 2px;
}

.c-list--link li a {
  text-decoration: none;
}

.c-list--link li a:hover {
  text-decoration: underline;
}

.c-list--horizontal {
  display: flex;
  align-items: center;
}

.c-list--horizontal li {
  margin-left: 25px;
  margin-bottom: 0;
}

.c-list--horizontal li:first-child {
  margin-left: 0;
}

.c-list--border li {
  background-position: left 16px;
  padding: 14px;
  border-bottom: 1px dashed #d4d4d4;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1;
}

.c-list--border li:last-child {
  border-bottom: 0;
}

.c-list--border li a {
  color: #231815;
  text-decoration: none;
}

.c-list--border li a:hover {
  color: #1ABAD5;
}

.c-list--order, .entry-content ol {
  padding-left: 20px;
  margin-bottom: 1em;
}

.c-list--order > li, .entry-content ol > li {
  margin-bottom: 5px;
  line-height: 32px;
  position: relative;
}

.c-list--order > li:last-child, .entry-content ol > li:last-child {
  margin-bottom: 0;
}

.c-list--note li {
  font-size: 13px;
  color: #8d8d8d;
  line-height: 1.5;
}

.c-list--note li::before {
  content: '※';
  margin-right: 3px;
}

/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */
.c-menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-menu {
    position: absolute;
    right: 12px;
    top: 14px;
    display: block;
  }
}

.c-menu-trigger,
.c-menu-trigger span {
  display: inline-block;
  transition: all .2s;
  box-sizing: border-box;
}

.c-menu-trigger {
  position: relative;
  width: 28px;
  height: 23px;
}

.c-menu-trigger-label {
  display: block;
  font-size: 8px;
  letter-spacing: 1.1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: auto;
  text-align: center;
  width: 100%;
  background-color: transparent;
  height: auto;
  color: #fff;
}

.c-menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #FEBD3D;
}

.c-menu-trigger span:nth-of-type(1) {
  top: 0;
}

.c-menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.c-menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.c-menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(10px) translateX(1px) rotate(-45deg);
  transform: translateY(10px) translateX(1px) rotate(-45deg);
}

.c-menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.c-menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-10px) translateX(1px) rotate(45deg);
  transform: translateY(-10px) translateX(1px) rotate(45deg);
}

.c-menu-recruit {
  right: 20px;
}

/* --------------------------
パネル
-------------------------- */
.c-panel {
  background-color: #fff;
  box-shadow: 0px 0px 10px #c5c5c5;
  position: relative;
}

.c-panel--border {
  border: 1px solid #c5c5c5;
  background-color: #fff;
}

.c-panel--rounded {
  border-radius: 8px;
}

.c-panel-contents {
  padding: 80px;
}

@media screen and (max-width: 768px) {
  .c-panel-contents {
    padding: 20px;
  }
}

.c-panel-contents--middle {
  padding: 40px;
}

@media screen and (max-width: 768px) {
  .c-panel-contents--middle {
    padding: 20px;
  }
}

.c-panel-contents--narrow {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .c-panel-contents--narrow {
    padding: 20px;
  }
}

/* --------------------------
セクション
-------------------------- */
@media screen and (min-width: 768px) {
  .c-section {
    margin: 80px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section {
    margin: 50px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--middle {
    margin: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section--middle {
    margin: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--narrow {
    margin: 30px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section--narrow {
    margin: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg {
    padding: 80px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section--bg {
    padding: 50px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--middle {
    padding: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section--bg--middle {
    padding: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--narrow {
    padding: 30px 0;
  }
}

@media screen and (max-width: 768px) {
  .c-section--bg--narrow {
    padding: 20px 0;
  }
}

.c-section-container {
  max-width: 1180px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .c-section-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1023px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 15px;
  }
}

.c-section-container-inner {
  max-width: 900px;
  margin: auto;
}

/* --------------------------
タブ
-------------------------- */
.c-tab-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 150px;
  border-bottom: 1px solid #c5c5c5;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .c-tab-nav {
    padding: 0 20px;
    margin: 0 -15px;
  }
}

.c-tab-nav-item {
  width: calc(100% /2);
  padding: 0 10px;
}

@media screen and (max-width: 768px) {
  .c-tab-nav-item {
    padding: 0 3px;
  }
}

.c-tab-nav-item:last-child a {
  border-right: 1px solid #c5c5c5;
}

.c-tab-nav-item a {
  background-color: #c5c5c5;
  border-right: 0;
  display: block;
  padding: 20px 10px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.1em;
  transition: none;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .c-tab-nav-item a {
    border-right: 1px solid #c5c5c5;
    font-size: 11px;
    padding: 15px 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3em;
  }
}

.c-tab-nav-item a[aria-selected="true"] {
  padding-bottom: 40px;
  border: 1px solid #c5c5c5;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
  background: none;
  color: #7ACC00;
  color: #7ACC00;
}

@media screen and (max-width: 768px) {
  .c-tab-nav-item a[aria-selected="true"] {
    padding-bottom: 20px;
    height: 46px;
  }
}

.c-tab-nav-item a[aria-selected="true"]:hover {
  opacity: 1;
}

.c-tab-nav.second {
  padding: 0 40px;
}

@media screen and (max-width: 768px) {
  .c-tab-nav.second {
    padding: 0 10px;
  }
}

.c-tab-nav.second li {
  width: calc(100% /4);
}

.c-tab-nav.third {
  padding: 0 40px;
  flex-wrap: unset;
}

@media screen and (max-width: 768px) {
  .c-tab-nav.third {
    padding: 0 10px;
  }
}

.c-tab-nav.third li {
  width: auto;
}

.c-tab-nav.third li a {
  padding: 20px 30px;
  font-size: 14px;
}

.c-tab-nav.third li a[aria-selected="true"] {
  padding-bottom: 40px;
  color: #FEBD3D;
}

@media screen and (max-width: 768px) {
  .c-tab-nav.third li a[aria-selected="true"] {
    padding-bottom: 20px;
  }
}

.c-tab-contents-item {
  display: none;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .c-tab-contents-item {
    padding: 30px 0;
  }
}

.c-tab-contents-item[aria-hidden="false"] {
  display: block;
}

/* --------------------------
テーブル
-------------------------- */
.c-table, .entry-content table {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 768px) {
  .c-table thead tr, .entry-content table thead tr {
    border-bottom: 0;
  }
}

.c-table thead th, .entry-content table thead th {
  padding: 25px;
  line-height: 1.5;
  font-weight: 400;
  width: 30%;
  background-color: #E3F0D0;
  vertical-align: top;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 768px) {
  .c-table thead th, .entry-content table thead th {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.c-table thead td, .entry-content table thead td {
  font-weight: 400;
  text-align: center;
  padding: 20px 30px;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 768px) {
  .c-table thead td, .entry-content table thead td {
    display: block;
    width: 100%;
  }
}

.c-table tbody tr, .entry-content table tbody tr {
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .c-table tbody tr, .entry-content table tbody tr {
    display: block;
    border-bottom: 0;
    font-size: 14px;
  }
}

.c-table tbody th, .entry-content table tbody th {
  padding: 25px;
  line-height: 1;
  font-weight: 400;
  width: 30%;
  background-color: #E3F0D0;
  border-bottom: 2px solid #F6F7FA;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .c-table tbody th, .entry-content table tbody th {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px;
    min-height: auto;
  }
}

.c-table tbody td, .entry-content table tbody td {
  padding: 20px 30px;
  border-bottom: 1px solid #F6F7FA;
}

@media screen and (max-width: 768px) {
  .c-table tbody td, .entry-content table tbody td {
    display: block;
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;
    border: none;
  }
}

.c-table--narrow {
  border-color: #c5c5c5;
}

.c-table--narrow tbody tr {
  border-color: #c5c5c5;
}

.c-table--narrow tbody th {
  padding: 8px 10px;
  line-height: 1.5;
  font-weight: normal;
  width: 120px;
  font-weight: 400;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .c-table--narrow tbody th {
    padding: 8px;
    width: 80px;
  }
}

.c-table--narrow tbody td {
  padding: 8px 10px 8px 20px;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .c-table--narrow tbody td {
    padding: 8px 10px 8px 15px;
  }
}

/* --------------------------
テキスト整列
-------------------------- */
.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-align-justify {
  text-align: justify;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .u-align-left--pc {
    text-align: left;
  }
  .u-align-right--pc {
    text-align: right;
  }
  .u-align-center--pc {
    text-align: center;
  }
}

@media screen and (max-width: 1180px) {
  .u-align-left--laptop {
    text-align: left;
  }
  .u-align-right--laptop {
    text-align: right;
  }
  .u-align-center--laptop {
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  .u-align-left--tab {
    text-align: left;
  }
  .u-align-right--tab {
    text-align: right;
  }
  .u-align-center--tab {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .u-align-left--sp {
    text-align: left;
  }
  .u-align-right--sp {
    text-align: right;
  }
  .u-align-center--sp {
    text-align: center;
  }
}

/* --------------------------
背景色
-------------------------- */
.u-bg-light {
  background-color: #F6F7FA;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-green {
  background-color: #d1f2ec;
}

.u-bg-blue {
  background-color: #1ABAD5;
}

.u-bg-gold {
  background-color: #dbc583;
}

.u-bg-blue-dark {
  background-color: #0b2b75;
}

.u-bg-red {
  background-color: #f8ebed;
}

/* --------------------------
枠線
-------------------------- */
.u-border-bottom {
  border-bottom: 1px solid #e20013;
}

.u-border-top {
  border-top: 1px solid #e20013;
}

.u-border-right {
  border-right: 1px solid #e20013;
}

.u-border-left {
  border-left: 1px solid #e20013;
}

.u-border--white {
  border-color: #fff;
}

/* --------------------------
Float解除
-------------------------- */
.u-clearfix::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

/* --------------------------
グリッド
-------------------------- */
.u-row {
  margin: -20px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .u-row {
    margin: -10px;
  }
}

.u-row-align-center {
  align-items: center;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

.u-row--nomargin {
  margin: 0;
}

.u-row--narrow {
  margin: 0 -10px;
}

.u-col-1 {
  width: 8.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-1 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-1 {
  margin-left: 8.3333333333%;
}

.u-row--nomargin .u-col-1 {
  padding: 0;
}

.u-row--narrow .u-col-1 {
  padding: 10px;
}

.u-col-2 {
  width: 16.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-2 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-2 {
  margin-left: 16.6666666667%;
}

.u-row--nomargin .u-col-2 {
  padding: 0;
}

.u-row--narrow .u-col-2 {
  padding: 10px;
}

.u-col-3 {
  width: 25%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-3 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-3 {
  margin-left: 25%;
}

.u-row--nomargin .u-col-3 {
  padding: 0;
}

.u-row--narrow .u-col-3 {
  padding: 10px;
}

.u-col-4 {
  width: 33.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-4 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-4 {
  margin-left: 33.3333333333%;
}

.u-row--nomargin .u-col-4 {
  padding: 0;
}

.u-row--narrow .u-col-4 {
  padding: 10px;
}

.u-col-5 {
  width: 41.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-5 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-5 {
  margin-left: 41.6666666667%;
}

.u-row--nomargin .u-col-5 {
  padding: 0;
}

.u-row--narrow .u-col-5 {
  padding: 10px;
}

.u-col-6 {
  width: 50%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-6 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-6 {
  margin-left: 50%;
}

.u-row--nomargin .u-col-6 {
  padding: 0;
}

.u-row--narrow .u-col-6 {
  padding: 10px;
}

.u-col-7 {
  width: 58.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-7 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-7 {
  margin-left: 58.3333333333%;
}

.u-row--nomargin .u-col-7 {
  padding: 0;
}

.u-row--narrow .u-col-7 {
  padding: 10px;
}

.u-col-8 {
  width: 66.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-8 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-8 {
  margin-left: 66.6666666667%;
}

.u-row--nomargin .u-col-8 {
  padding: 0;
}

.u-row--narrow .u-col-8 {
  padding: 10px;
}

.u-col-9 {
  width: 75%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-9 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-9 {
  margin-left: 75%;
}

.u-row--nomargin .u-col-9 {
  padding: 0;
}

.u-row--narrow .u-col-9 {
  padding: 10px;
}

.u-col-10 {
  width: 83.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-10 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-10 {
  margin-left: 83.3333333333%;
}

.u-row--nomargin .u-col-10 {
  padding: 0;
}

.u-row--narrow .u-col-10 {
  padding: 10px;
}

.u-col-11 {
  width: 91.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-11 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-11 {
  margin-left: 91.6666666667%;
}

.u-row--nomargin .u-col-11 {
  padding: 0;
}

.u-row--narrow .u-col-11 {
  padding: 10px;
}

.u-col-12 {
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .u-col-12 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-12 {
  margin-left: 100%;
}

.u-row--nomargin .u-col-12 {
  padding: 0;
}

.u-row--narrow .u-col-12 {
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .u-col-1--pc {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-1--pc {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-1--pc {
    padding: 10px;
  }
  .u-col-2--pc {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-2--pc {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-2--pc {
    padding: 10px;
  }
  .u-col-3--pc {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-3--pc {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-3--pc {
    padding: 10px;
  }
  .u-col-4--pc {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-4--pc {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-4--pc {
    padding: 10px;
  }
  .u-col-5--pc {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-5--pc {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-5--pc {
    padding: 10px;
  }
  .u-col-6--pc {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-6--pc {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-6--pc {
    padding: 10px;
  }
  .u-col-7--pc {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-7--pc {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-7--pc {
    padding: 10px;
  }
  .u-col-8--pc {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-8--pc {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-8--pc {
    padding: 10px;
  }
  .u-col-9--pc {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-9--pc {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-9--pc {
    padding: 10px;
  }
  .u-col-10--pc {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-10--pc {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-10--pc {
    padding: 10px;
  }
  .u-col-11--pc {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-11--pc {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-11--pc {
    padding: 10px;
  }
  .u-col-12--pc {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-12--pc {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-12--pc {
    padding: 10px;
  }
}

@media screen and (max-width: 1180px) {
  .u-col-1--laptop {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-1--laptop {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-1--laptop {
    padding: 10px;
  }
  .u-col-2--laptop {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-2--laptop {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-2--laptop {
    padding: 10px;
  }
  .u-col-3--laptop {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-3--laptop {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-3--laptop {
    padding: 10px;
  }
  .u-col-4--laptop {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-4--laptop {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-4--laptop {
    padding: 10px;
  }
  .u-col-5--laptop {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-5--laptop {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-5--laptop {
    padding: 10px;
  }
  .u-col-6--laptop {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-6--laptop {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-6--laptop {
    padding: 10px;
  }
  .u-col-7--laptop {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-7--laptop {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-7--laptop {
    padding: 10px;
  }
  .u-col-8--laptop {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-8--laptop {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-8--laptop {
    padding: 10px;
  }
  .u-col-9--laptop {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-9--laptop {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-9--laptop {
    padding: 10px;
  }
  .u-col-10--laptop {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-10--laptop {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-10--laptop {
    padding: 10px;
  }
  .u-col-11--laptop {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-11--laptop {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-11--laptop {
    padding: 10px;
  }
  .u-col-12--laptop {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-12--laptop {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-12--laptop {
    padding: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .u-col-1--tab {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-1--tab {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-1--tab {
    padding: 10px;
  }
  .u-col-2--tab {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-2--tab {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-2--tab {
    padding: 10px;
  }
  .u-col-3--tab {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-3--tab {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-3--tab {
    padding: 10px;
  }
  .u-col-4--tab {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-4--tab {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-4--tab {
    padding: 10px;
  }
  .u-col-5--tab {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-5--tab {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-5--tab {
    padding: 10px;
  }
  .u-col-6--tab {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-6--tab {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-6--tab {
    padding: 10px;
  }
  .u-col-7--tab {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-7--tab {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-7--tab {
    padding: 10px;
  }
  .u-col-8--tab {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-8--tab {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-8--tab {
    padding: 10px;
  }
  .u-col-9--tab {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-9--tab {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-9--tab {
    padding: 10px;
  }
  .u-col-10--tab {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-10--tab {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-10--tab {
    padding: 10px;
  }
  .u-col-11--tab {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-11--tab {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-11--tab {
    padding: 10px;
  }
  .u-col-12--tab {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-12--tab {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-12--tab {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-1--sp {
    width: 8.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-1--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-1--sp {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-1--sp {
    padding: 10px;
  }
  .u-col-2--sp {
    width: 16.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-2--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-2--sp {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-2--sp {
    padding: 10px;
  }
  .u-col-3--sp {
    width: 25%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-3--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-3--sp {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-3--sp {
    padding: 10px;
  }
  .u-col-4--sp {
    width: 33.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-4--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-4--sp {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-4--sp {
    padding: 10px;
  }
  .u-col-5--sp {
    width: 41.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-5--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-5--sp {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-5--sp {
    padding: 10px;
  }
  .u-col-6--sp {
    width: 50%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-6--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-6--sp {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-6--sp {
    padding: 10px;
  }
  .u-col-7--sp {
    width: 58.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-7--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-7--sp {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-7--sp {
    padding: 10px;
  }
  .u-col-8--sp {
    width: 66.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-8--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-8--sp {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-8--sp {
    padding: 10px;
  }
  .u-col-9--sp {
    width: 75%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-9--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-9--sp {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-9--sp {
    padding: 10px;
  }
  .u-col-10--sp {
    width: 83.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-10--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-10--sp {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-10--sp {
    padding: 10px;
  }
  .u-col-11--sp {
    width: 91.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-11--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-11--sp {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-11--sp {
    padding: 10px;
  }
  .u-col-12--sp {
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .u-col-12--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-12--sp {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-12--sp {
    padding: 10px;
  }
}

/* --------------------------
非表示切り替え
-------------------------- */
@media screen and (min-width: 768px) {
  .u-hide--pc {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .u-hide--tab {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .u-hide--laptop {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .u-hide--sp {
    display: none;
  }
}

/* --------------------------
margin、padding指定
-------------------------- */
.u-mt0 {
  margin-top: 0px !important;
}

.u-mr0 {
  margin-right: 0px !important;
}

.u-ml0 {
  margin-left: 0px !important;
}

.u-mb0 {
  margin-bottom: 0px !important;
}

.u-pt0 {
  padding-top: 0px !important;
}

.u-pr0 {
  padding-right: 0px !important;
}

.u-pl0 {
  padding-left: 0px !important;
}

.u-pb0 {
  padding-bottom: 0px !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-mt85 {
  margin-top: 85px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-mb85 {
  margin-bottom: 85px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-mt95 {
  margin-top: 95px !important;
}

.u-mr95 {
  margin-right: 95px !important;
}

.u-ml95 {
  margin-left: 95px !important;
}

.u-mb95 {
  margin-bottom: 95px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pr95 {
  padding-right: 95px !important;
}

.u-pl95 {
  padding-left: 95px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

@media screen and (max-width: 768px) {
  .u-mt0--sp {
    margin-top: 0px !important;
  }
  .u-mr0--sp {
    margin-right: 0px !important;
  }
  .u-ml0--sp {
    margin-left: 0px !important;
  }
  .u-mb0--sp {
    margin-bottom: 0px !important;
  }
  .u-pt0--sp {
    padding-top: 0px !important;
  }
  .u-pr0--sp {
    padding-right: 0px !important;
  }
  .u-pl0--sp {
    padding-left: 0px !important;
  }
  .u-pb0--sp {
    padding-bottom: 0px !important;
  }
  .u-mt5--sp {
    margin-top: 5px !important;
  }
  .u-mr5--sp {
    margin-right: 5px !important;
  }
  .u-ml5--sp {
    margin-left: 5px !important;
  }
  .u-mb5--sp {
    margin-bottom: 5px !important;
  }
  .u-pt5--sp {
    padding-top: 5px !important;
  }
  .u-pr5--sp {
    padding-right: 5px !important;
  }
  .u-pl5--sp {
    padding-left: 5px !important;
  }
  .u-pb5--sp {
    padding-bottom: 5px !important;
  }
  .u-mt10--sp {
    margin-top: 10px !important;
  }
  .u-mr10--sp {
    margin-right: 10px !important;
  }
  .u-ml10--sp {
    margin-left: 10px !important;
  }
  .u-mb10--sp {
    margin-bottom: 10px !important;
  }
  .u-pt10--sp {
    padding-top: 10px !important;
  }
  .u-pr10--sp {
    padding-right: 10px !important;
  }
  .u-pl10--sp {
    padding-left: 10px !important;
  }
  .u-pb10--sp {
    padding-bottom: 10px !important;
  }
  .u-mt15--sp {
    margin-top: 15px !important;
  }
  .u-mr15--sp {
    margin-right: 15px !important;
  }
  .u-ml15--sp {
    margin-left: 15px !important;
  }
  .u-mb15--sp {
    margin-bottom: 15px !important;
  }
  .u-pt15--sp {
    padding-top: 15px !important;
  }
  .u-pr15--sp {
    padding-right: 15px !important;
  }
  .u-pl15--sp {
    padding-left: 15px !important;
  }
  .u-pb15--sp {
    padding-bottom: 15px !important;
  }
  .u-mt20--sp {
    margin-top: 20px !important;
  }
  .u-mr20--sp {
    margin-right: 20px !important;
  }
  .u-ml20--sp {
    margin-left: 20px !important;
  }
  .u-mb20--sp {
    margin-bottom: 20px !important;
  }
  .u-pt20--sp {
    padding-top: 20px !important;
  }
  .u-pr20--sp {
    padding-right: 20px !important;
  }
  .u-pl20--sp {
    padding-left: 20px !important;
  }
  .u-pb20--sp {
    padding-bottom: 20px !important;
  }
  .u-mt25--sp {
    margin-top: 25px !important;
  }
  .u-mr25--sp {
    margin-right: 25px !important;
  }
  .u-ml25--sp {
    margin-left: 25px !important;
  }
  .u-mb25--sp {
    margin-bottom: 25px !important;
  }
  .u-pt25--sp {
    padding-top: 25px !important;
  }
  .u-pr25--sp {
    padding-right: 25px !important;
  }
  .u-pl25--sp {
    padding-left: 25px !important;
  }
  .u-pb25--sp {
    padding-bottom: 25px !important;
  }
  .u-mt30--sp {
    margin-top: 30px !important;
  }
  .u-mr30--sp {
    margin-right: 30px !important;
  }
  .u-ml30--sp {
    margin-left: 30px !important;
  }
  .u-mb30--sp {
    margin-bottom: 30px !important;
  }
  .u-pt30--sp {
    padding-top: 30px !important;
  }
  .u-pr30--sp {
    padding-right: 30px !important;
  }
  .u-pl30--sp {
    padding-left: 30px !important;
  }
  .u-pb30--sp {
    padding-bottom: 30px !important;
  }
  .u-mt35--sp {
    margin-top: 35px !important;
  }
  .u-mr35--sp {
    margin-right: 35px !important;
  }
  .u-ml35--sp {
    margin-left: 35px !important;
  }
  .u-mb35--sp {
    margin-bottom: 35px !important;
  }
  .u-pt35--sp {
    padding-top: 35px !important;
  }
  .u-pr35--sp {
    padding-right: 35px !important;
  }
  .u-pl35--sp {
    padding-left: 35px !important;
  }
  .u-pb35--sp {
    padding-bottom: 35px !important;
  }
  .u-mt40--sp {
    margin-top: 40px !important;
  }
  .u-mr40--sp {
    margin-right: 40px !important;
  }
  .u-ml40--sp {
    margin-left: 40px !important;
  }
  .u-mb40--sp {
    margin-bottom: 40px !important;
  }
  .u-pt40--sp {
    padding-top: 40px !important;
  }
  .u-pr40--sp {
    padding-right: 40px !important;
  }
  .u-pl40--sp {
    padding-left: 40px !important;
  }
  .u-pb40--sp {
    padding-bottom: 40px !important;
  }
  .u-mt45--sp {
    margin-top: 45px !important;
  }
  .u-mr45--sp {
    margin-right: 45px !important;
  }
  .u-ml45--sp {
    margin-left: 45px !important;
  }
  .u-mb45--sp {
    margin-bottom: 45px !important;
  }
  .u-pt45--sp {
    padding-top: 45px !important;
  }
  .u-pr45--sp {
    padding-right: 45px !important;
  }
  .u-pl45--sp {
    padding-left: 45px !important;
  }
  .u-pb45--sp {
    padding-bottom: 45px !important;
  }
  .u-mt50--sp {
    margin-top: 50px !important;
  }
  .u-mr50--sp {
    margin-right: 50px !important;
  }
  .u-ml50--sp {
    margin-left: 50px !important;
  }
  .u-mb50--sp {
    margin-bottom: 50px !important;
  }
  .u-pt50--sp {
    padding-top: 50px !important;
  }
  .u-pr50--sp {
    padding-right: 50px !important;
  }
  .u-pl50--sp {
    padding-left: 50px !important;
  }
  .u-pb50--sp {
    padding-bottom: 50px !important;
  }
  .u-mt55--sp {
    margin-top: 55px !important;
  }
  .u-mr55--sp {
    margin-right: 55px !important;
  }
  .u-ml55--sp {
    margin-left: 55px !important;
  }
  .u-mb55--sp {
    margin-bottom: 55px !important;
  }
  .u-pt55--sp {
    padding-top: 55px !important;
  }
  .u-pr55--sp {
    padding-right: 55px !important;
  }
  .u-pl55--sp {
    padding-left: 55px !important;
  }
  .u-pb55--sp {
    padding-bottom: 55px !important;
  }
  .u-mt60--sp {
    margin-top: 60px !important;
  }
  .u-mr60--sp {
    margin-right: 60px !important;
  }
  .u-ml60--sp {
    margin-left: 60px !important;
  }
  .u-mb60--sp {
    margin-bottom: 60px !important;
  }
  .u-pt60--sp {
    padding-top: 60px !important;
  }
  .u-pr60--sp {
    padding-right: 60px !important;
  }
  .u-pl60--sp {
    padding-left: 60px !important;
  }
  .u-pb60--sp {
    padding-bottom: 60px !important;
  }
  .u-mt65--sp {
    margin-top: 65px !important;
  }
  .u-mr65--sp {
    margin-right: 65px !important;
  }
  .u-ml65--sp {
    margin-left: 65px !important;
  }
  .u-mb65--sp {
    margin-bottom: 65px !important;
  }
  .u-pt65--sp {
    padding-top: 65px !important;
  }
  .u-pr65--sp {
    padding-right: 65px !important;
  }
  .u-pl65--sp {
    padding-left: 65px !important;
  }
  .u-pb65--sp {
    padding-bottom: 65px !important;
  }
  .u-mt70--sp {
    margin-top: 70px !important;
  }
  .u-mr70--sp {
    margin-right: 70px !important;
  }
  .u-ml70--sp {
    margin-left: 70px !important;
  }
  .u-mb70--sp {
    margin-bottom: 70px !important;
  }
  .u-pt70--sp {
    padding-top: 70px !important;
  }
  .u-pr70--sp {
    padding-right: 70px !important;
  }
  .u-pl70--sp {
    padding-left: 70px !important;
  }
  .u-pb70--sp {
    padding-bottom: 70px !important;
  }
  .u-mt75--sp {
    margin-top: 75px !important;
  }
  .u-mr75--sp {
    margin-right: 75px !important;
  }
  .u-ml75--sp {
    margin-left: 75px !important;
  }
  .u-mb75--sp {
    margin-bottom: 75px !important;
  }
  .u-pt75--sp {
    padding-top: 75px !important;
  }
  .u-pr75--sp {
    padding-right: 75px !important;
  }
  .u-pl75--sp {
    padding-left: 75px !important;
  }
  .u-pb75--sp {
    padding-bottom: 75px !important;
  }
  .u-mt80--sp {
    margin-top: 80px !important;
  }
  .u-mr80--sp {
    margin-right: 80px !important;
  }
  .u-ml80--sp {
    margin-left: 80px !important;
  }
  .u-mb80--sp {
    margin-bottom: 80px !important;
  }
  .u-pt80--sp {
    padding-top: 80px !important;
  }
  .u-pr80--sp {
    padding-right: 80px !important;
  }
  .u-pl80--sp {
    padding-left: 80px !important;
  }
  .u-pb80--sp {
    padding-bottom: 80px !important;
  }
  .u-mt85--sp {
    margin-top: 85px !important;
  }
  .u-mr85--sp {
    margin-right: 85px !important;
  }
  .u-ml85--sp {
    margin-left: 85px !important;
  }
  .u-mb85--sp {
    margin-bottom: 85px !important;
  }
  .u-pt85--sp {
    padding-top: 85px !important;
  }
  .u-pr85--sp {
    padding-right: 85px !important;
  }
  .u-pl85--sp {
    padding-left: 85px !important;
  }
  .u-pb85--sp {
    padding-bottom: 85px !important;
  }
  .u-mt90--sp {
    margin-top: 90px !important;
  }
  .u-mr90--sp {
    margin-right: 90px !important;
  }
  .u-ml90--sp {
    margin-left: 90px !important;
  }
  .u-mb90--sp {
    margin-bottom: 90px !important;
  }
  .u-pt90--sp {
    padding-top: 90px !important;
  }
  .u-pr90--sp {
    padding-right: 90px !important;
  }
  .u-pl90--sp {
    padding-left: 90px !important;
  }
  .u-pb90--sp {
    padding-bottom: 90px !important;
  }
  .u-mt95--sp {
    margin-top: 95px !important;
  }
  .u-mr95--sp {
    margin-right: 95px !important;
  }
  .u-ml95--sp {
    margin-left: 95px !important;
  }
  .u-mb95--sp {
    margin-bottom: 95px !important;
  }
  .u-pt95--sp {
    padding-top: 95px !important;
  }
  .u-pr95--sp {
    padding-right: 95px !important;
  }
  .u-pl95--sp {
    padding-left: 95px !important;
  }
  .u-pb95--sp {
    padding-bottom: 95px !important;
  }
  .u-mt100--sp {
    margin-top: 100px !important;
  }
  .u-mr100--sp {
    margin-right: 100px !important;
  }
  .u-ml100--sp {
    margin-left: 100px !important;
  }
  .u-mb100--sp {
    margin-bottom: 100px !important;
  }
  .u-pt100--sp {
    padding-top: 100px !important;
  }
  .u-pr100--sp {
    padding-right: 100px !important;
  }
  .u-pl100--sp {
    padding-left: 100px !important;
  }
  .u-pb100--sp {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-mt0--tab {
    margin-top: 0px !important;
  }
  .u-mr0--tab {
    margin-right: 0px !important;
  }
  .u-ml0--tab {
    margin-left: 0px !important;
  }
  .u-mb0--tab {
    margin-bottom: 0px !important;
  }
  .u-pt0--tab {
    padding-top: 0px !important;
  }
  .u-pr0--tab {
    padding-right: 0px !important;
  }
  .u-pl0--tab {
    padding-left: 0px !important;
  }
  .u-pb0--tab {
    padding-bottom: 0px !important;
  }
  .u-mt5--tab {
    margin-top: 5px !important;
  }
  .u-mr5--tab {
    margin-right: 5px !important;
  }
  .u-ml5--tab {
    margin-left: 5px !important;
  }
  .u-mb5--tab {
    margin-bottom: 5px !important;
  }
  .u-pt5--tab {
    padding-top: 5px !important;
  }
  .u-pr5--tab {
    padding-right: 5px !important;
  }
  .u-pl5--tab {
    padding-left: 5px !important;
  }
  .u-pb5--tab {
    padding-bottom: 5px !important;
  }
  .u-mt10--tab {
    margin-top: 10px !important;
  }
  .u-mr10--tab {
    margin-right: 10px !important;
  }
  .u-ml10--tab {
    margin-left: 10px !important;
  }
  .u-mb10--tab {
    margin-bottom: 10px !important;
  }
  .u-pt10--tab {
    padding-top: 10px !important;
  }
  .u-pr10--tab {
    padding-right: 10px !important;
  }
  .u-pl10--tab {
    padding-left: 10px !important;
  }
  .u-pb10--tab {
    padding-bottom: 10px !important;
  }
  .u-mt15--tab {
    margin-top: 15px !important;
  }
  .u-mr15--tab {
    margin-right: 15px !important;
  }
  .u-ml15--tab {
    margin-left: 15px !important;
  }
  .u-mb15--tab {
    margin-bottom: 15px !important;
  }
  .u-pt15--tab {
    padding-top: 15px !important;
  }
  .u-pr15--tab {
    padding-right: 15px !important;
  }
  .u-pl15--tab {
    padding-left: 15px !important;
  }
  .u-pb15--tab {
    padding-bottom: 15px !important;
  }
  .u-mt20--tab {
    margin-top: 20px !important;
  }
  .u-mr20--tab {
    margin-right: 20px !important;
  }
  .u-ml20--tab {
    margin-left: 20px !important;
  }
  .u-mb20--tab {
    margin-bottom: 20px !important;
  }
  .u-pt20--tab {
    padding-top: 20px !important;
  }
  .u-pr20--tab {
    padding-right: 20px !important;
  }
  .u-pl20--tab {
    padding-left: 20px !important;
  }
  .u-pb20--tab {
    padding-bottom: 20px !important;
  }
  .u-mt25--tab {
    margin-top: 25px !important;
  }
  .u-mr25--tab {
    margin-right: 25px !important;
  }
  .u-ml25--tab {
    margin-left: 25px !important;
  }
  .u-mb25--tab {
    margin-bottom: 25px !important;
  }
  .u-pt25--tab {
    padding-top: 25px !important;
  }
  .u-pr25--tab {
    padding-right: 25px !important;
  }
  .u-pl25--tab {
    padding-left: 25px !important;
  }
  .u-pb25--tab {
    padding-bottom: 25px !important;
  }
  .u-mt30--tab {
    margin-top: 30px !important;
  }
  .u-mr30--tab {
    margin-right: 30px !important;
  }
  .u-ml30--tab {
    margin-left: 30px !important;
  }
  .u-mb30--tab {
    margin-bottom: 30px !important;
  }
  .u-pt30--tab {
    padding-top: 30px !important;
  }
  .u-pr30--tab {
    padding-right: 30px !important;
  }
  .u-pl30--tab {
    padding-left: 30px !important;
  }
  .u-pb30--tab {
    padding-bottom: 30px !important;
  }
  .u-mt35--tab {
    margin-top: 35px !important;
  }
  .u-mr35--tab {
    margin-right: 35px !important;
  }
  .u-ml35--tab {
    margin-left: 35px !important;
  }
  .u-mb35--tab {
    margin-bottom: 35px !important;
  }
  .u-pt35--tab {
    padding-top: 35px !important;
  }
  .u-pr35--tab {
    padding-right: 35px !important;
  }
  .u-pl35--tab {
    padding-left: 35px !important;
  }
  .u-pb35--tab {
    padding-bottom: 35px !important;
  }
  .u-mt40--tab {
    margin-top: 40px !important;
  }
  .u-mr40--tab {
    margin-right: 40px !important;
  }
  .u-ml40--tab {
    margin-left: 40px !important;
  }
  .u-mb40--tab {
    margin-bottom: 40px !important;
  }
  .u-pt40--tab {
    padding-top: 40px !important;
  }
  .u-pr40--tab {
    padding-right: 40px !important;
  }
  .u-pl40--tab {
    padding-left: 40px !important;
  }
  .u-pb40--tab {
    padding-bottom: 40px !important;
  }
  .u-mt45--tab {
    margin-top: 45px !important;
  }
  .u-mr45--tab {
    margin-right: 45px !important;
  }
  .u-ml45--tab {
    margin-left: 45px !important;
  }
  .u-mb45--tab {
    margin-bottom: 45px !important;
  }
  .u-pt45--tab {
    padding-top: 45px !important;
  }
  .u-pr45--tab {
    padding-right: 45px !important;
  }
  .u-pl45--tab {
    padding-left: 45px !important;
  }
  .u-pb45--tab {
    padding-bottom: 45px !important;
  }
  .u-mt50--tab {
    margin-top: 50px !important;
  }
  .u-mr50--tab {
    margin-right: 50px !important;
  }
  .u-ml50--tab {
    margin-left: 50px !important;
  }
  .u-mb50--tab {
    margin-bottom: 50px !important;
  }
  .u-pt50--tab {
    padding-top: 50px !important;
  }
  .u-pr50--tab {
    padding-right: 50px !important;
  }
  .u-pl50--tab {
    padding-left: 50px !important;
  }
  .u-pb50--tab {
    padding-bottom: 50px !important;
  }
  .u-mt55--tab {
    margin-top: 55px !important;
  }
  .u-mr55--tab {
    margin-right: 55px !important;
  }
  .u-ml55--tab {
    margin-left: 55px !important;
  }
  .u-mb55--tab {
    margin-bottom: 55px !important;
  }
  .u-pt55--tab {
    padding-top: 55px !important;
  }
  .u-pr55--tab {
    padding-right: 55px !important;
  }
  .u-pl55--tab {
    padding-left: 55px !important;
  }
  .u-pb55--tab {
    padding-bottom: 55px !important;
  }
  .u-mt60--tab {
    margin-top: 60px !important;
  }
  .u-mr60--tab {
    margin-right: 60px !important;
  }
  .u-ml60--tab {
    margin-left: 60px !important;
  }
  .u-mb60--tab {
    margin-bottom: 60px !important;
  }
  .u-pt60--tab {
    padding-top: 60px !important;
  }
  .u-pr60--tab {
    padding-right: 60px !important;
  }
  .u-pl60--tab {
    padding-left: 60px !important;
  }
  .u-pb60--tab {
    padding-bottom: 60px !important;
  }
  .u-mt65--tab {
    margin-top: 65px !important;
  }
  .u-mr65--tab {
    margin-right: 65px !important;
  }
  .u-ml65--tab {
    margin-left: 65px !important;
  }
  .u-mb65--tab {
    margin-bottom: 65px !important;
  }
  .u-pt65--tab {
    padding-top: 65px !important;
  }
  .u-pr65--tab {
    padding-right: 65px !important;
  }
  .u-pl65--tab {
    padding-left: 65px !important;
  }
  .u-pb65--tab {
    padding-bottom: 65px !important;
  }
  .u-mt70--tab {
    margin-top: 70px !important;
  }
  .u-mr70--tab {
    margin-right: 70px !important;
  }
  .u-ml70--tab {
    margin-left: 70px !important;
  }
  .u-mb70--tab {
    margin-bottom: 70px !important;
  }
  .u-pt70--tab {
    padding-top: 70px !important;
  }
  .u-pr70--tab {
    padding-right: 70px !important;
  }
  .u-pl70--tab {
    padding-left: 70px !important;
  }
  .u-pb70--tab {
    padding-bottom: 70px !important;
  }
  .u-mt75--tab {
    margin-top: 75px !important;
  }
  .u-mr75--tab {
    margin-right: 75px !important;
  }
  .u-ml75--tab {
    margin-left: 75px !important;
  }
  .u-mb75--tab {
    margin-bottom: 75px !important;
  }
  .u-pt75--tab {
    padding-top: 75px !important;
  }
  .u-pr75--tab {
    padding-right: 75px !important;
  }
  .u-pl75--tab {
    padding-left: 75px !important;
  }
  .u-pb75--tab {
    padding-bottom: 75px !important;
  }
  .u-mt80--tab {
    margin-top: 80px !important;
  }
  .u-mr80--tab {
    margin-right: 80px !important;
  }
  .u-ml80--tab {
    margin-left: 80px !important;
  }
  .u-mb80--tab {
    margin-bottom: 80px !important;
  }
  .u-pt80--tab {
    padding-top: 80px !important;
  }
  .u-pr80--tab {
    padding-right: 80px !important;
  }
  .u-pl80--tab {
    padding-left: 80px !important;
  }
  .u-pb80--tab {
    padding-bottom: 80px !important;
  }
  .u-mt85--tab {
    margin-top: 85px !important;
  }
  .u-mr85--tab {
    margin-right: 85px !important;
  }
  .u-ml85--tab {
    margin-left: 85px !important;
  }
  .u-mb85--tab {
    margin-bottom: 85px !important;
  }
  .u-pt85--tab {
    padding-top: 85px !important;
  }
  .u-pr85--tab {
    padding-right: 85px !important;
  }
  .u-pl85--tab {
    padding-left: 85px !important;
  }
  .u-pb85--tab {
    padding-bottom: 85px !important;
  }
  .u-mt90--tab {
    margin-top: 90px !important;
  }
  .u-mr90--tab {
    margin-right: 90px !important;
  }
  .u-ml90--tab {
    margin-left: 90px !important;
  }
  .u-mb90--tab {
    margin-bottom: 90px !important;
  }
  .u-pt90--tab {
    padding-top: 90px !important;
  }
  .u-pr90--tab {
    padding-right: 90px !important;
  }
  .u-pl90--tab {
    padding-left: 90px !important;
  }
  .u-pb90--tab {
    padding-bottom: 90px !important;
  }
  .u-mt95--tab {
    margin-top: 95px !important;
  }
  .u-mr95--tab {
    margin-right: 95px !important;
  }
  .u-ml95--tab {
    margin-left: 95px !important;
  }
  .u-mb95--tab {
    margin-bottom: 95px !important;
  }
  .u-pt95--tab {
    padding-top: 95px !important;
  }
  .u-pr95--tab {
    padding-right: 95px !important;
  }
  .u-pl95--tab {
    padding-left: 95px !important;
  }
  .u-pb95--tab {
    padding-bottom: 95px !important;
  }
  .u-mt100--tab {
    margin-top: 100px !important;
  }
  .u-mr100--tab {
    margin-right: 100px !important;
  }
  .u-ml100--tab {
    margin-left: 100px !important;
  }
  .u-mb100--tab {
    margin-bottom: 100px !important;
  }
  .u-pt100--tab {
    padding-top: 100px !important;
  }
  .u-pr100--tab {
    padding-right: 100px !important;
  }
  .u-pl100--tab {
    padding-left: 100px !important;
  }
  .u-pb100--tab {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1180px) {
  .u-mt0--laptop {
    margin-top: 0px !important;
  }
  .u-mr0--laptop {
    margin-right: 0px !important;
  }
  .u-ml0--laptop {
    margin-left: 0px !important;
  }
  .u-mb0--laptop {
    margin-bottom: 0px !important;
  }
  .u-pt0--laptop {
    padding-top: 0px !important;
  }
  .u-pr0--laptop {
    padding-right: 0px !important;
  }
  .u-pl0--laptop {
    padding-left: 0px !important;
  }
  .u-pb0--laptop {
    padding-bottom: 0px !important;
  }
  .u-mt5--laptop {
    margin-top: 5px !important;
  }
  .u-mr5--laptop {
    margin-right: 5px !important;
  }
  .u-ml5--laptop {
    margin-left: 5px !important;
  }
  .u-mb5--laptop {
    margin-bottom: 5px !important;
  }
  .u-pt5--laptop {
    padding-top: 5px !important;
  }
  .u-pr5--laptop {
    padding-right: 5px !important;
  }
  .u-pl5--laptop {
    padding-left: 5px !important;
  }
  .u-pb5--laptop {
    padding-bottom: 5px !important;
  }
  .u-mt10--laptop {
    margin-top: 10px !important;
  }
  .u-mr10--laptop {
    margin-right: 10px !important;
  }
  .u-ml10--laptop {
    margin-left: 10px !important;
  }
  .u-mb10--laptop {
    margin-bottom: 10px !important;
  }
  .u-pt10--laptop {
    padding-top: 10px !important;
  }
  .u-pr10--laptop {
    padding-right: 10px !important;
  }
  .u-pl10--laptop {
    padding-left: 10px !important;
  }
  .u-pb10--laptop {
    padding-bottom: 10px !important;
  }
  .u-mt15--laptop {
    margin-top: 15px !important;
  }
  .u-mr15--laptop {
    margin-right: 15px !important;
  }
  .u-ml15--laptop {
    margin-left: 15px !important;
  }
  .u-mb15--laptop {
    margin-bottom: 15px !important;
  }
  .u-pt15--laptop {
    padding-top: 15px !important;
  }
  .u-pr15--laptop {
    padding-right: 15px !important;
  }
  .u-pl15--laptop {
    padding-left: 15px !important;
  }
  .u-pb15--laptop {
    padding-bottom: 15px !important;
  }
  .u-mt20--laptop {
    margin-top: 20px !important;
  }
  .u-mr20--laptop {
    margin-right: 20px !important;
  }
  .u-ml20--laptop {
    margin-left: 20px !important;
  }
  .u-mb20--laptop {
    margin-bottom: 20px !important;
  }
  .u-pt20--laptop {
    padding-top: 20px !important;
  }
  .u-pr20--laptop {
    padding-right: 20px !important;
  }
  .u-pl20--laptop {
    padding-left: 20px !important;
  }
  .u-pb20--laptop {
    padding-bottom: 20px !important;
  }
  .u-mt25--laptop {
    margin-top: 25px !important;
  }
  .u-mr25--laptop {
    margin-right: 25px !important;
  }
  .u-ml25--laptop {
    margin-left: 25px !important;
  }
  .u-mb25--laptop {
    margin-bottom: 25px !important;
  }
  .u-pt25--laptop {
    padding-top: 25px !important;
  }
  .u-pr25--laptop {
    padding-right: 25px !important;
  }
  .u-pl25--laptop {
    padding-left: 25px !important;
  }
  .u-pb25--laptop {
    padding-bottom: 25px !important;
  }
  .u-mt30--laptop {
    margin-top: 30px !important;
  }
  .u-mr30--laptop {
    margin-right: 30px !important;
  }
  .u-ml30--laptop {
    margin-left: 30px !important;
  }
  .u-mb30--laptop {
    margin-bottom: 30px !important;
  }
  .u-pt30--laptop {
    padding-top: 30px !important;
  }
  .u-pr30--laptop {
    padding-right: 30px !important;
  }
  .u-pl30--laptop {
    padding-left: 30px !important;
  }
  .u-pb30--laptop {
    padding-bottom: 30px !important;
  }
  .u-mt35--laptop {
    margin-top: 35px !important;
  }
  .u-mr35--laptop {
    margin-right: 35px !important;
  }
  .u-ml35--laptop {
    margin-left: 35px !important;
  }
  .u-mb35--laptop {
    margin-bottom: 35px !important;
  }
  .u-pt35--laptop {
    padding-top: 35px !important;
  }
  .u-pr35--laptop {
    padding-right: 35px !important;
  }
  .u-pl35--laptop {
    padding-left: 35px !important;
  }
  .u-pb35--laptop {
    padding-bottom: 35px !important;
  }
  .u-mt40--laptop {
    margin-top: 40px !important;
  }
  .u-mr40--laptop {
    margin-right: 40px !important;
  }
  .u-ml40--laptop {
    margin-left: 40px !important;
  }
  .u-mb40--laptop {
    margin-bottom: 40px !important;
  }
  .u-pt40--laptop {
    padding-top: 40px !important;
  }
  .u-pr40--laptop {
    padding-right: 40px !important;
  }
  .u-pl40--laptop {
    padding-left: 40px !important;
  }
  .u-pb40--laptop {
    padding-bottom: 40px !important;
  }
  .u-mt45--laptop {
    margin-top: 45px !important;
  }
  .u-mr45--laptop {
    margin-right: 45px !important;
  }
  .u-ml45--laptop {
    margin-left: 45px !important;
  }
  .u-mb45--laptop {
    margin-bottom: 45px !important;
  }
  .u-pt45--laptop {
    padding-top: 45px !important;
  }
  .u-pr45--laptop {
    padding-right: 45px !important;
  }
  .u-pl45--laptop {
    padding-left: 45px !important;
  }
  .u-pb45--laptop {
    padding-bottom: 45px !important;
  }
  .u-mt50--laptop {
    margin-top: 50px !important;
  }
  .u-mr50--laptop {
    margin-right: 50px !important;
  }
  .u-ml50--laptop {
    margin-left: 50px !important;
  }
  .u-mb50--laptop {
    margin-bottom: 50px !important;
  }
  .u-pt50--laptop {
    padding-top: 50px !important;
  }
  .u-pr50--laptop {
    padding-right: 50px !important;
  }
  .u-pl50--laptop {
    padding-left: 50px !important;
  }
  .u-pb50--laptop {
    padding-bottom: 50px !important;
  }
  .u-mt55--laptop {
    margin-top: 55px !important;
  }
  .u-mr55--laptop {
    margin-right: 55px !important;
  }
  .u-ml55--laptop {
    margin-left: 55px !important;
  }
  .u-mb55--laptop {
    margin-bottom: 55px !important;
  }
  .u-pt55--laptop {
    padding-top: 55px !important;
  }
  .u-pr55--laptop {
    padding-right: 55px !important;
  }
  .u-pl55--laptop {
    padding-left: 55px !important;
  }
  .u-pb55--laptop {
    padding-bottom: 55px !important;
  }
  .u-mt60--laptop {
    margin-top: 60px !important;
  }
  .u-mr60--laptop {
    margin-right: 60px !important;
  }
  .u-ml60--laptop {
    margin-left: 60px !important;
  }
  .u-mb60--laptop {
    margin-bottom: 60px !important;
  }
  .u-pt60--laptop {
    padding-top: 60px !important;
  }
  .u-pr60--laptop {
    padding-right: 60px !important;
  }
  .u-pl60--laptop {
    padding-left: 60px !important;
  }
  .u-pb60--laptop {
    padding-bottom: 60px !important;
  }
  .u-mt65--laptop {
    margin-top: 65px !important;
  }
  .u-mr65--laptop {
    margin-right: 65px !important;
  }
  .u-ml65--laptop {
    margin-left: 65px !important;
  }
  .u-mb65--laptop {
    margin-bottom: 65px !important;
  }
  .u-pt65--laptop {
    padding-top: 65px !important;
  }
  .u-pr65--laptop {
    padding-right: 65px !important;
  }
  .u-pl65--laptop {
    padding-left: 65px !important;
  }
  .u-pb65--laptop {
    padding-bottom: 65px !important;
  }
  .u-mt70--laptop {
    margin-top: 70px !important;
  }
  .u-mr70--laptop {
    margin-right: 70px !important;
  }
  .u-ml70--laptop {
    margin-left: 70px !important;
  }
  .u-mb70--laptop {
    margin-bottom: 70px !important;
  }
  .u-pt70--laptop {
    padding-top: 70px !important;
  }
  .u-pr70--laptop {
    padding-right: 70px !important;
  }
  .u-pl70--laptop {
    padding-left: 70px !important;
  }
  .u-pb70--laptop {
    padding-bottom: 70px !important;
  }
  .u-mt75--laptop {
    margin-top: 75px !important;
  }
  .u-mr75--laptop {
    margin-right: 75px !important;
  }
  .u-ml75--laptop {
    margin-left: 75px !important;
  }
  .u-mb75--laptop {
    margin-bottom: 75px !important;
  }
  .u-pt75--laptop {
    padding-top: 75px !important;
  }
  .u-pr75--laptop {
    padding-right: 75px !important;
  }
  .u-pl75--laptop {
    padding-left: 75px !important;
  }
  .u-pb75--laptop {
    padding-bottom: 75px !important;
  }
  .u-mt80--laptop {
    margin-top: 80px !important;
  }
  .u-mr80--laptop {
    margin-right: 80px !important;
  }
  .u-ml80--laptop {
    margin-left: 80px !important;
  }
  .u-mb80--laptop {
    margin-bottom: 80px !important;
  }
  .u-pt80--laptop {
    padding-top: 80px !important;
  }
  .u-pr80--laptop {
    padding-right: 80px !important;
  }
  .u-pl80--laptop {
    padding-left: 80px !important;
  }
  .u-pb80--laptop {
    padding-bottom: 80px !important;
  }
  .u-mt85--laptop {
    margin-top: 85px !important;
  }
  .u-mr85--laptop {
    margin-right: 85px !important;
  }
  .u-ml85--laptop {
    margin-left: 85px !important;
  }
  .u-mb85--laptop {
    margin-bottom: 85px !important;
  }
  .u-pt85--laptop {
    padding-top: 85px !important;
  }
  .u-pr85--laptop {
    padding-right: 85px !important;
  }
  .u-pl85--laptop {
    padding-left: 85px !important;
  }
  .u-pb85--laptop {
    padding-bottom: 85px !important;
  }
  .u-mt90--laptop {
    margin-top: 90px !important;
  }
  .u-mr90--laptop {
    margin-right: 90px !important;
  }
  .u-ml90--laptop {
    margin-left: 90px !important;
  }
  .u-mb90--laptop {
    margin-bottom: 90px !important;
  }
  .u-pt90--laptop {
    padding-top: 90px !important;
  }
  .u-pr90--laptop {
    padding-right: 90px !important;
  }
  .u-pl90--laptop {
    padding-left: 90px !important;
  }
  .u-pb90--laptop {
    padding-bottom: 90px !important;
  }
  .u-mt95--laptop {
    margin-top: 95px !important;
  }
  .u-mr95--laptop {
    margin-right: 95px !important;
  }
  .u-ml95--laptop {
    margin-left: 95px !important;
  }
  .u-mb95--laptop {
    margin-bottom: 95px !important;
  }
  .u-pt95--laptop {
    padding-top: 95px !important;
  }
  .u-pr95--laptop {
    padding-right: 95px !important;
  }
  .u-pl95--laptop {
    padding-left: 95px !important;
  }
  .u-pb95--laptop {
    padding-bottom: 95px !important;
  }
  .u-mt100--laptop {
    margin-top: 100px !important;
  }
  .u-mr100--laptop {
    margin-right: 100px !important;
  }
  .u-ml100--laptop {
    margin-left: 100px !important;
  }
  .u-mb100--laptop {
    margin-bottom: 100px !important;
  }
  .u-pt100--laptop {
    padding-top: 100px !important;
  }
  .u-pr100--laptop {
    padding-right: 100px !important;
  }
  .u-pl100--laptop {
    padding-left: 100px !important;
  }
  .u-pb100--laptop {
    padding-bottom: 100px !important;
  }
}

@media screen and (min-width: 768px) {
  .u-mt0--pc {
    margin-top: 0px !important;
  }
  .u-mr0--pc {
    margin-right: 0px !important;
  }
  .u-ml0--pc {
    margin-left: 0px !important;
  }
  .u-mb0--pc {
    margin-bottom: 0px !important;
  }
  .u-pt0--pc {
    padding-top: 0px !important;
  }
  .u-pr0--pc {
    padding-right: 0px !important;
  }
  .u-pl0--pc {
    padding-left: 0px !important;
  }
  .u-pb0--pc {
    padding-bottom: 0px !important;
  }
  .u-mt5--pc {
    margin-top: 5px !important;
  }
  .u-mr5--pc {
    margin-right: 5px !important;
  }
  .u-ml5--pc {
    margin-left: 5px !important;
  }
  .u-mb5--pc {
    margin-bottom: 5px !important;
  }
  .u-pt5--pc {
    padding-top: 5px !important;
  }
  .u-pr5--pc {
    padding-right: 5px !important;
  }
  .u-pl5--pc {
    padding-left: 5px !important;
  }
  .u-pb5--pc {
    padding-bottom: 5px !important;
  }
  .u-mt10--pc {
    margin-top: 10px !important;
  }
  .u-mr10--pc {
    margin-right: 10px !important;
  }
  .u-ml10--pc {
    margin-left: 10px !important;
  }
  .u-mb10--pc {
    margin-bottom: 10px !important;
  }
  .u-pt10--pc {
    padding-top: 10px !important;
  }
  .u-pr10--pc {
    padding-right: 10px !important;
  }
  .u-pl10--pc {
    padding-left: 10px !important;
  }
  .u-pb10--pc {
    padding-bottom: 10px !important;
  }
  .u-mt15--pc {
    margin-top: 15px !important;
  }
  .u-mr15--pc {
    margin-right: 15px !important;
  }
  .u-ml15--pc {
    margin-left: 15px !important;
  }
  .u-mb15--pc {
    margin-bottom: 15px !important;
  }
  .u-pt15--pc {
    padding-top: 15px !important;
  }
  .u-pr15--pc {
    padding-right: 15px !important;
  }
  .u-pl15--pc {
    padding-left: 15px !important;
  }
  .u-pb15--pc {
    padding-bottom: 15px !important;
  }
  .u-mt20--pc {
    margin-top: 20px !important;
  }
  .u-mr20--pc {
    margin-right: 20px !important;
  }
  .u-ml20--pc {
    margin-left: 20px !important;
  }
  .u-mb20--pc {
    margin-bottom: 20px !important;
  }
  .u-pt20--pc {
    padding-top: 20px !important;
  }
  .u-pr20--pc {
    padding-right: 20px !important;
  }
  .u-pl20--pc {
    padding-left: 20px !important;
  }
  .u-pb20--pc {
    padding-bottom: 20px !important;
  }
  .u-mt25--pc {
    margin-top: 25px !important;
  }
  .u-mr25--pc {
    margin-right: 25px !important;
  }
  .u-ml25--pc {
    margin-left: 25px !important;
  }
  .u-mb25--pc {
    margin-bottom: 25px !important;
  }
  .u-pt25--pc {
    padding-top: 25px !important;
  }
  .u-pr25--pc {
    padding-right: 25px !important;
  }
  .u-pl25--pc {
    padding-left: 25px !important;
  }
  .u-pb25--pc {
    padding-bottom: 25px !important;
  }
  .u-mt30--pc {
    margin-top: 30px !important;
  }
  .u-mr30--pc {
    margin-right: 30px !important;
  }
  .u-ml30--pc {
    margin-left: 30px !important;
  }
  .u-mb30--pc {
    margin-bottom: 30px !important;
  }
  .u-pt30--pc {
    padding-top: 30px !important;
  }
  .u-pr30--pc {
    padding-right: 30px !important;
  }
  .u-pl30--pc {
    padding-left: 30px !important;
  }
  .u-pb30--pc {
    padding-bottom: 30px !important;
  }
  .u-mt35--pc {
    margin-top: 35px !important;
  }
  .u-mr35--pc {
    margin-right: 35px !important;
  }
  .u-ml35--pc {
    margin-left: 35px !important;
  }
  .u-mb35--pc {
    margin-bottom: 35px !important;
  }
  .u-pt35--pc {
    padding-top: 35px !important;
  }
  .u-pr35--pc {
    padding-right: 35px !important;
  }
  .u-pl35--pc {
    padding-left: 35px !important;
  }
  .u-pb35--pc {
    padding-bottom: 35px !important;
  }
  .u-mt40--pc {
    margin-top: 40px !important;
  }
  .u-mr40--pc {
    margin-right: 40px !important;
  }
  .u-ml40--pc {
    margin-left: 40px !important;
  }
  .u-mb40--pc {
    margin-bottom: 40px !important;
  }
  .u-pt40--pc {
    padding-top: 40px !important;
  }
  .u-pr40--pc {
    padding-right: 40px !important;
  }
  .u-pl40--pc {
    padding-left: 40px !important;
  }
  .u-pb40--pc {
    padding-bottom: 40px !important;
  }
  .u-mt45--pc {
    margin-top: 45px !important;
  }
  .u-mr45--pc {
    margin-right: 45px !important;
  }
  .u-ml45--pc {
    margin-left: 45px !important;
  }
  .u-mb45--pc {
    margin-bottom: 45px !important;
  }
  .u-pt45--pc {
    padding-top: 45px !important;
  }
  .u-pr45--pc {
    padding-right: 45px !important;
  }
  .u-pl45--pc {
    padding-left: 45px !important;
  }
  .u-pb45--pc {
    padding-bottom: 45px !important;
  }
  .u-mt50--pc {
    margin-top: 50px !important;
  }
  .u-mr50--pc {
    margin-right: 50px !important;
  }
  .u-ml50--pc {
    margin-left: 50px !important;
  }
  .u-mb50--pc {
    margin-bottom: 50px !important;
  }
  .u-pt50--pc {
    padding-top: 50px !important;
  }
  .u-pr50--pc {
    padding-right: 50px !important;
  }
  .u-pl50--pc {
    padding-left: 50px !important;
  }
  .u-pb50--pc {
    padding-bottom: 50px !important;
  }
  .u-mt55--pc {
    margin-top: 55px !important;
  }
  .u-mr55--pc {
    margin-right: 55px !important;
  }
  .u-ml55--pc {
    margin-left: 55px !important;
  }
  .u-mb55--pc {
    margin-bottom: 55px !important;
  }
  .u-pt55--pc {
    padding-top: 55px !important;
  }
  .u-pr55--pc {
    padding-right: 55px !important;
  }
  .u-pl55--pc {
    padding-left: 55px !important;
  }
  .u-pb55--pc {
    padding-bottom: 55px !important;
  }
  .u-mt60--pc {
    margin-top: 60px !important;
  }
  .u-mr60--pc {
    margin-right: 60px !important;
  }
  .u-ml60--pc {
    margin-left: 60px !important;
  }
  .u-mb60--pc {
    margin-bottom: 60px !important;
  }
  .u-pt60--pc {
    padding-top: 60px !important;
  }
  .u-pr60--pc {
    padding-right: 60px !important;
  }
  .u-pl60--pc {
    padding-left: 60px !important;
  }
  .u-pb60--pc {
    padding-bottom: 60px !important;
  }
  .u-mt65--pc {
    margin-top: 65px !important;
  }
  .u-mr65--pc {
    margin-right: 65px !important;
  }
  .u-ml65--pc {
    margin-left: 65px !important;
  }
  .u-mb65--pc {
    margin-bottom: 65px !important;
  }
  .u-pt65--pc {
    padding-top: 65px !important;
  }
  .u-pr65--pc {
    padding-right: 65px !important;
  }
  .u-pl65--pc {
    padding-left: 65px !important;
  }
  .u-pb65--pc {
    padding-bottom: 65px !important;
  }
  .u-mt70--pc {
    margin-top: 70px !important;
  }
  .u-mr70--pc {
    margin-right: 70px !important;
  }
  .u-ml70--pc {
    margin-left: 70px !important;
  }
  .u-mb70--pc {
    margin-bottom: 70px !important;
  }
  .u-pt70--pc {
    padding-top: 70px !important;
  }
  .u-pr70--pc {
    padding-right: 70px !important;
  }
  .u-pl70--pc {
    padding-left: 70px !important;
  }
  .u-pb70--pc {
    padding-bottom: 70px !important;
  }
  .u-mt75--pc {
    margin-top: 75px !important;
  }
  .u-mr75--pc {
    margin-right: 75px !important;
  }
  .u-ml75--pc {
    margin-left: 75px !important;
  }
  .u-mb75--pc {
    margin-bottom: 75px !important;
  }
  .u-pt75--pc {
    padding-top: 75px !important;
  }
  .u-pr75--pc {
    padding-right: 75px !important;
  }
  .u-pl75--pc {
    padding-left: 75px !important;
  }
  .u-pb75--pc {
    padding-bottom: 75px !important;
  }
  .u-mt80--pc {
    margin-top: 80px !important;
  }
  .u-mr80--pc {
    margin-right: 80px !important;
  }
  .u-ml80--pc {
    margin-left: 80px !important;
  }
  .u-mb80--pc {
    margin-bottom: 80px !important;
  }
  .u-pt80--pc {
    padding-top: 80px !important;
  }
  .u-pr80--pc {
    padding-right: 80px !important;
  }
  .u-pl80--pc {
    padding-left: 80px !important;
  }
  .u-pb80--pc {
    padding-bottom: 80px !important;
  }
  .u-mt85--pc {
    margin-top: 85px !important;
  }
  .u-mr85--pc {
    margin-right: 85px !important;
  }
  .u-ml85--pc {
    margin-left: 85px !important;
  }
  .u-mb85--pc {
    margin-bottom: 85px !important;
  }
  .u-pt85--pc {
    padding-top: 85px !important;
  }
  .u-pr85--pc {
    padding-right: 85px !important;
  }
  .u-pl85--pc {
    padding-left: 85px !important;
  }
  .u-pb85--pc {
    padding-bottom: 85px !important;
  }
  .u-mt90--pc {
    margin-top: 90px !important;
  }
  .u-mr90--pc {
    margin-right: 90px !important;
  }
  .u-ml90--pc {
    margin-left: 90px !important;
  }
  .u-mb90--pc {
    margin-bottom: 90px !important;
  }
  .u-pt90--pc {
    padding-top: 90px !important;
  }
  .u-pr90--pc {
    padding-right: 90px !important;
  }
  .u-pl90--pc {
    padding-left: 90px !important;
  }
  .u-pb90--pc {
    padding-bottom: 90px !important;
  }
  .u-mt95--pc {
    margin-top: 95px !important;
  }
  .u-mr95--pc {
    margin-right: 95px !important;
  }
  .u-ml95--pc {
    margin-left: 95px !important;
  }
  .u-mb95--pc {
    margin-bottom: 95px !important;
  }
  .u-pt95--pc {
    padding-top: 95px !important;
  }
  .u-pr95--pc {
    padding-right: 95px !important;
  }
  .u-pl95--pc {
    padding-left: 95px !important;
  }
  .u-pb95--pc {
    padding-bottom: 95px !important;
  }
  .u-mt100--pc {
    margin-top: 100px !important;
  }
  .u-mr100--pc {
    margin-right: 100px !important;
  }
  .u-ml100--pc {
    margin-left: 100px !important;
  }
  .u-mb100--pc {
    margin-bottom: 100px !important;
  }
  .u-pt100--pc {
    padding-top: 100px !important;
  }
  .u-pr100--pc {
    padding-right: 100px !important;
  }
  .u-pl100--pc {
    padding-left: 100px !important;
  }
  .u-pb100--pc {
    padding-bottom: 100px !important;
  }
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-text {
  line-height: 2em;
}

.u-text-accent {
  color: #0b2b75;
}

.u-text-important {
  color: #FEBD3D;
}

.u-text-inverse {
  color: #fff;
}

.u-text-sub {
  color: #ababab;
}

.u-text-pink {
  color: #FF9DAB;
}

.u-text-pink::after {
  background-color: #FF9DAB;
}

.u-text-blue {
  color: #81C2F9;
}

.u-text-blue::after {
  background-color: #81C2F9;
}

.u-text-purple {
  color: #BDA6F8;
}

.u-text-purple::after {
  background-color: #BDA6F8;
}

.u-text-orange {
  color: #FEB675;
}

.u-text-orange::after {
  background-color: #FEB675;
}

.u-text-red {
  color: #FA6C60;
}

.u-text-red::after {
  background-color: #FA6C60;
}

.u-text-sky {
  color: #BDA6F8;
}

.u-text-sky::after {
  background-color: #BDA6F8;
}

.u-text-perple {
  color: #FEB675;
}

.u-text-perple::after {
  background-color: #FEB675;
}

.u-text-serif {
  font-family: 'Times New Roman', Times, serif;
}

.u-text-messege {
  font-family: 'Kiwi Maru', serif !important;
}

.u-text-bold {
  font-weight: 400;
}

.u-text-normal {
  font-weight: 300;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-x-large {
  font-size: 26px;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .u-text-x-large {
    font-size: 22px;
  }
}

.u-text-large {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .u-text-large {
    font-size: 20px;
    line-height: 36px;
  }
}

.u-text-bit-large {
  font-size: 18px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .u-text-bit-large {
    font-size: 18px;
    line-height: 32px;
  }
}

.u-text-default {
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 768px) {
  .u-text-default {
    font-size: 16px;
    line-height: 22px;
  }
}

.u-text-small {
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .u-text-small {
    font-size: 12px;
    line-height: 16px;
  }
}

.u-text-x-small {
  font-size: 12px;
  line-height: 18px;
}

@media screen and (max-width: 768px) {
  .u-text-x-small {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 768px) {
  .u-text-x-small--sp {
    font-size: 12px;
    line-height: 16px;
  }
}

/* --------------------------
幅指定
-------------------------- */
.u-w0 {
  width: 0%;
  display: block;
}

.u-w10 {
  width: 10%;
  display: block;
}

.u-w20 {
  width: 20%;
  display: block;
}

.u-w30 {
  width: 30%;
  display: block;
}

.u-w40 {
  width: 40%;
  display: block;
}

.u-w50 {
  width: 50%;
  display: block;
}

.u-w60 {
  width: 60%;
  display: block;
}

.u-w70 {
  width: 70%;
  display: block;
}

.u-w80 {
  width: 80%;
  display: block;
}

.u-w90 {
  width: 90%;
  display: block;
}

.u-w100 {
  width: 100%;
  display: block;
}

@media screen and (min-width: 768px) {
  .u-w0--pc {
    width: 0%;
    display: block;
  }
  .u-w10--pc {
    width: 10%;
    display: block;
  }
  .u-w20--pc {
    width: 20%;
    display: block;
  }
  .u-w30--pc {
    width: 30%;
    display: block;
  }
  .u-w40--pc {
    width: 40%;
    display: block;
  }
  .u-w50--pc {
    width: 50%;
    display: block;
  }
  .u-w60--pc {
    width: 60%;
    display: block;
  }
  .u-w70--pc {
    width: 70%;
    display: block;
  }
  .u-w80--pc {
    width: 80%;
    display: block;
  }
  .u-w90--pc {
    width: 90%;
    display: block;
  }
  .u-w100--pc {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 1180px) {
  .u-w0--laptop {
    width: 0%;
    display: block;
  }
  .u-w10--laptop {
    width: 10%;
    display: block;
  }
  .u-w20--laptop {
    width: 20%;
    display: block;
  }
  .u-w30--laptop {
    width: 30%;
    display: block;
  }
  .u-w40--laptop {
    width: 40%;
    display: block;
  }
  .u-w50--laptop {
    width: 50%;
    display: block;
  }
  .u-w60--laptop {
    width: 60%;
    display: block;
  }
  .u-w70--laptop {
    width: 70%;
    display: block;
  }
  .u-w80--laptop {
    width: 80%;
    display: block;
  }
  .u-w90--laptop {
    width: 90%;
    display: block;
  }
  .u-w100--laptop {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .u-w0--tab {
    width: 0%;
    display: block;
  }
  .u-w10--tab {
    width: 10%;
    display: block;
  }
  .u-w20--tab {
    width: 20%;
    display: block;
  }
  .u-w30--tab {
    width: 30%;
    display: block;
  }
  .u-w40--tab {
    width: 40%;
    display: block;
  }
  .u-w50--tab {
    width: 50%;
    display: block;
  }
  .u-w60--tab {
    width: 60%;
    display: block;
  }
  .u-w70--tab {
    width: 70%;
    display: block;
  }
  .u-w80--tab {
    width: 80%;
    display: block;
  }
  .u-w90--tab {
    width: 90%;
    display: block;
  }
  .u-w100--tab {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .u-w0--sp {
    width: 0%;
    display: block;
  }
  .u-w10--sp {
    width: 10%;
    display: block;
  }
  .u-w20--sp {
    width: 20%;
    display: block;
  }
  .u-w30--sp {
    width: 30%;
    display: block;
  }
  .u-w40--sp {
    width: 40%;
    display: block;
  }
  .u-w50--sp {
    width: 50%;
    display: block;
  }
  .u-w60--sp {
    width: 60%;
    display: block;
  }
  .u-w70--sp {
    width: 70%;
    display: block;
  }
  .u-w80--sp {
    width: 80%;
    display: block;
  }
  .u-w90--sp {
    width: 90%;
    display: block;
  }
  .u-w100--sp {
    width: 100%;
    display: block;
  }
}

.u-h100 {
  height: 100%;
}

.p-header {
  padding: 10px 0 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 768px) {
  .p-header {
    height: 50px;
    padding: 0;
  }
}

.p-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px 10px;
}

@media screen and (max-width: 768px) {
  .p-header-top {
    padding: 10px 0;
    height: 100%;
    padding-right: 60px;
  }
}

.p-header-logo {
  text-align: center;
}

.p-header-logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1023px) {
  .p-header-logo a {
    margin-left: 10px;
    font-size: 11px;
    display: block;
    letter-spacing: 0.1em;
  }
}

.p-header-logo a img {
  max-width: 250px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .p-header-logo a img {
    width: 170px;
    padding-right: 0;
    display: block;
    margin-bottom: 2px;
  }
}

.p-header-cta {
  display: flex;
  align-items: center;
}

.p-header-tel {
  color: #7ACC00;
  text-decoration: none;
  padding-right: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .p-header-tel {
    font-size: 0;
  }
}

.p-header-tel i {
  font-size: 25px;
  padding-right: 10px;
}

@media screen and (max-width: 768px) {
  .p-header-tel i {
    padding: 0;
    font-size: 20px;
    color: #FEBD3D;
  }
}

@media screen and (max-width: 768px) {
  .p-header-entry {
    font-size: 0;
    padding: 0;
    background: none;
  }
}

.p-header-entry i {
  padding-right: 5px;
}

@media screen and (max-width: 768px) {
  .p-header-entry i {
    padding: 0;
    font-size: 25px;
    color: #FEBD3D;
  }
}

@media screen and (max-width: 768px) {
  .p-gnav {
    display: none;
    height: calc(100vh - 50px);
    overflow: scroll;
  }
}

.p-gnav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
  padding: 10px 20px 0;
  transition: all .3s ease-in-out;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .p-gnav-list {
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    padding: 0;
    border-top: 1px solid #c5c5c5;
  }
}

.p-gnav-list-item {
  width: calc(100% /8);
  position: relative;
  border-left: 1px solid #c5c5c5;
  height: 62px;
}

@media all and (-ms-high-contrast: none) {
  .p-gnav-list-item {
    width: 12.5%;
  }
}

@media screen and (max-width: 1023px) {
  .p-gnav-list-item {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item {
    padding: 0;
    border: none;
    border-bottom: 1px solid #c5c5c5;
    width: 100%;
    height: auto;
  }
}

.p-gnav-list-item:nth-last-child(1) {
  border-right: 1px solid #c5c5c5;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item:nth-last-child(1) {
    border-right: none;
  }
}

.p-gnav-list-item.current > a {
  border-bottom: 5px solid #FEBD3D;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item.current > a {
    border-bottom: none;
  }
}

.p-gnav-list-item > a {
  font-size: 13px;
  letter-spacing: 0.05em;
  color: #717171;
  text-decoration: none;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.p-gnav-list-item > a:hover {
  border-bottom: 5px solid #FEBD3D;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item > a {
    padding: 15px 20px;
    text-align: left;
    justify-content: end;
  }
  .p-gnav-list-item > a.nav-open::after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-right: 2px solid #c5c5c5;
    border-top: 2px solid #c5c5c5;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 20px;
    top: 20px;
  }
  .p-gnav-list-item > a:hover {
    border-bottom: none;
  }
}

.p-gnav-list-item ol {
  display: none;
  list-style: none;
  position: absolute;
  width: 100%;
  top: 62px;
  left: 0;
  background: #7ACC00;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item ol {
    position: unset;
    flex-wrap: wrap;
    padding-left: 20px;
  }
}

.p-gnav-list-item ol li {
  border-bottom: 1px solid #fff;
}

.p-gnav-list-item ol li:nth-last-child(1) {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item ol li {
    border: none;
  }
}

.p-gnav-list-item ol li > a {
  font-size: 13px;
  line-height: 1.3em;
  letter-spacing: 0.05em;
  padding: 10px;
  display: block;
  text-align: left;
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item ol li > a {
    position: relative;
    padding-left: 20px;
    display: block;
  }
  .p-gnav-list-item ol li > a::before {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 18px;
    left: 8px;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item:hover ol {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .p-gnav-list-item:hover ol.active {
    display: flex !important;
  }
}

.p-footer {
  padding: 80px 0 20px;
  color: #717171;
  background: white;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .p-footer {
    padding: 40px 0 20px;
  }
}

.p-footer-top {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .p-footer-top {
    margin: 0 -15px;
  }
}

.p-footer-top-cta {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #F6F7FA;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .p-footer-top-cta {
    width: 100%;
  }
}

.p-footer-top-cta-tel {
  padding: 0;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .p-footer-top-cta-tel {
    font-size: 30px;
    padding: 0;
  }
}

.p-footer-top-cta-tel i {
  color: #7ACC00;
}

@media screen and (max-width: 768px) {
  .p-footer-top-cta-tel i {
    padding-right: 10px;
  }
}

.p-footer-top-youtube {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .p-footer-top-youtube {
    width: 100%;
  }
}

.p-footer-top-youtube-wrap {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.p-footer-top-youtube-wrap iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.p-footer-logo {
  text-align: center;
  max-width: 300px;
  margin: 30px auto;
}

@media screen and (max-width: 768px) {
  .p-footer-logo {
    margin: 20px auto;
    max-width: 250px;
  }
}

.p-footer-copyright {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .p-footer-copyright {
    text-align: center;
    display: block;
  }
}

.p-footer-copyright a {
  text-decoration: none;
  color: #717171;
}

.p-footer-nav {
  margin-top: 40px;
}

.p-footer-nav ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

@media screen and (max-width: 768px) {
  .p-footer-nav ul {
    margin-bottom: 40px;
  }
}

.p-footer-nav ul > li {
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 15px;
  width: calc(100%/ 4);
}

@media screen and (max-width: 768px) {
  .p-footer-nav ul > li {
    width: 100%;
    margin-bottom: 0;
    font-size: 13px;
  }
}

.p-footer-nav ul > li > a {
  color: #717171;
  text-decoration: none;
  border-left: 5px solid #7ACC00;
  padding: 5px 10px;
  display: block;
}

.p-footer-nav ul > li > a:hover {
  opacity: 1;
}

.p-footer-nav ul > li ol {
  list-style: none;
  padding: 10px 10px 0;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .p-footer-nav ul > li ol {
    font-size: 11px;
    display: flex;
    flex-wrap: wrap;
  }
}

.p-footer-nav ul > li ol > li {
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .p-footer-nav ul > li ol > li {
    width: 50%;
  }
}

.p-footer-nav ul > li ol > li:nth-last-child(1) {
  padding-bottom: 0;
}

.p-footer-nav ul > li ol > li > a {
  color: #717171;
  text-decoration: none;
}

.p-footer-nav ul > li ol > li > a:hover {
  opacity: 1;
}

.p-footer-pagetop {
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #ababab;
  border-radius: 0.5rem;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  padding: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  z-index: 9000;
}

.p-footer-pagetop i {
  font-size: 150%;
  line-height: 60px;
  -webkit-transition: all .3s;
  transition: all .3s;
  letter-spacing: 0;
}

.p-footer-pagetop:hover i {
  line-height: 55px;
}

@media screen and (max-width: 768px) {
  .p-footer-pagetop {
    width: 35px;
    height: 35px;
    font-size: 14px;
    bottom: 5px;
    right: 5px;
  }
  .p-footer-pagetop i {
    line-height: 35px;
  }
  .p-footer-pagetop:hover i {
    line-height: 35px;
  }
}

.p-sidenav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
}

@media screen and (max-width: 768px) {
  .p-sidenav {
    display: none;
  }
}

.p-sidenav a {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  display: block;
  padding: 40px 10px 20px;
  margin-bottom: 10px;
  background: #FEBD3D;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  position: relative;
}

@media all and (-ms-high-contrast: none) {
  .p-sidenav a {
    height: 180px;
  }
  .p-sidenav a:nth-child(3) {
    height: 230px;
  }
}

.p-sidenav a::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  left: 10px;
  top: 14px;
}

.p-sidenav a::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #FEBD3D;
  border-top: 2px solid #FEBD3D;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 14px;
  top: 19px;
}

.p-sidenav a.pink {
  background: #FF9DAB;
}

.p-sidenav a.pink::after {
  border-color: #FF9DAB;
}

.p-sidenav a.blue {
  background: #81C2F9;
}

.p-sidenav a.blue::after {
  border-color: #81C2F9;
}

.top {
  position: relative;
}

.top-bg {
  background: url(../images/top/bg.jpg) no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.top-mv {
  overflow: hidden;
}

.top-news {
  position: relative;
}

.top-news-list {
  margin-bottom: 40px;
}

.top-news-list a {
  display: block;
  text-decoration: none;
}

.top-news-list a dl {
  margin-bottom: 20px;
  display: flex;
  letter-spacing: 0.1em;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .top-news-list a dl {
    flex-wrap: wrap;
  }
}

.top-news-list a dl dt {
  font-size: 12px;
  padding-right: 20px;
  font-family: 'Montserrat', sans-serif;
}

.top-news-list a dl dd {
  font-size: 14px;
  line-height: 1.2em;
}

@media screen and (max-width: 768px) {
  .top-news-list a dl dd:nth-last-child(1) {
    width: 100%;
  }
}

.top-news-list a dl dd span {
  width: 130px;
  font-size: 10px;
  background: #7ACC00;
  color: white;
  padding: 10px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
  line-height: 1em;
}

@media screen and (max-width: 768px) {
  .top-news-list a dl dd span {
    display: block;
    margin: 0;
    margin-bottom: 5px;
    padding: 6px;
  }
}

.top-news-list a dl dd span.info {
  background: #FF9DAB;
}

.top-news-list a dl dd span.orient {
  background: #81C2F9;
}

.top-news-list a dl dd span.tour {
  background: #BDA6F8;
}

.top-news-list a dl dd span.fair {
  background: #FEB675;
}

.top-news-list a dl dd span.event {
  background: #FA6C60;
}

@media screen and (max-width: 768px) {
  .top-news-list a dl dd {
    font-size: 12px;
  }
}

.top-recruit-wrap {
  overflow-y: scroll;
  padding-right: 20px;
  height: 500px;
}

@media screen and (max-width: 768px) {
  .top-recruit-wrap {
    padding-right: 10px;
    height: 350px;
    margin-bottom: 20px;
  }
}

.top-recruit-wrap ul li {
  padding: 10px;
}

.top-recruit-wrap ul li .c-panel-contents--narrow {
  padding: 15px;
}

@media screen and (max-width: 768px) {
  .top-recruit-wrap ul li .recruit-new-text {
    padding-bottom: 0;
  }
}

.top-recruit-wrap ul li .recruit-new-link {
  font-size: 10px;
  letter-spacing: 0.05em;
  right: 50px;
}

@media screen and (max-width: 768px) {
  .top-recruit-wrap ul li .recruit-new-link {
    bottom: 10px;
  }
}

.top-recruit-wrap ul li .recruit-new-link::before {
  width: 20px;
  top: 6px;
  right: -30px;
}

.top-recruit-wrap ul li .recruit-new-link::after {
  top: 3px;
  right: -31px;
}

.top-link {
  overflow-x: hidden;
}

.top-link li {
  margin-bottom: 120px;
  height: 500px;
}

.top-link li:nth-child(1) {
  background-image: url(../images/company/mv.jpg);
  background-size: 70%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(2) {
  background: url(../images/news/mv.jpg);
  background-size: 70%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(3) {
  background-image: url(../images/about/mv.jpg);
  background-size: 70%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(4) {
  background-image: url(../images/other/mv.jpg);
  background-size: 70%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(5) {
  background-image: url(../images/welfare/mv.jpg);
  background-size: 70%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(6) {
  background-image: url(../images/recruit/mv.jpg);
  background-size: 70%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.top-link li:nth-child(7) {
  background-image: url(../images/info/mv.jpg);
  background-size: 70%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .top-link li {
    height: 200px;
    background-size: cover !important;
    margin-bottom: 160px;
    background-position: center !important;
  }
}

.top-link li .c-section-container {
  position: relative;
  height: 100%;
}

.top-link-text {
  position: absolute;
  bottom: -60px;
  left: -40px;
  display: flex;
  align-items: center;
  background: white;
  width: 500px;
}

@media screen and (max-width: 768px) {
  .top-link-text {
    width: 80%;
    left: 0;
    bottom: -130px;
  }
}

.top-link-text-inner {
  padding: 60px;
}

@media screen and (max-width: 768px) {
  .top-link-text-inner {
    padding: 30px;
  }
}

.top-link-text-inner h3 {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.08em;
  font-weight: 300;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-link-text-inner h3 {
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.top-link-text-inner h3::before {
  content: "";
  width: 150px;
  height: 2px;
  background: #7ACC00;
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-link-text-inner a {
  width: auto;
  border: none;
  font-size: 12px;
  background: none;
  color: #7ACC00;
  padding: 0;
}

.top-link-text-inner a::before {
  content: "";
  width: 30px;
  height: 1px;
  background: #7ACC00;
  position: absolute;
  top: 8px;
  right: -40px;
}

.top-link-text-inner a::after {
  content: "";
  width: 10px;
  height: 1px;
  border: none;
  background: #7ACC00;
  position: absolute;
  top: 5px;
  right: -41px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.top-link-text.right {
  left: unset;
  right: -40px;
}

@media screen and (max-width: 768px) {
  .top-link-text.right {
    right: 0;
  }
}

.top-panel {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  height: 800px;
}

@media screen and (max-width: 768px) {
  .top-panel {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .top-panel {
    margin: 1px;
  }
}

.top-panel li {
  margin: 5px;
  position: relative;
  overflow: hidden;
  background: #000;
}

@media screen and (max-width: 768px) {
  .top-panel li {
    margin: 1px;
  }
}

.top-panel li::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  transition: all .8s ease;
}

@media screen and (max-width: 768px) {
  .top-panel li::after {
    opacity: .8;
  }
}

.top-panel li:hover::after {
  opacity: .3;
  transform: scale(1.05);
}

.top-panel li a {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-decoration: none;
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 20px;
  line-height: 1.5em;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  opacity: 1;
  text-shadow: 0 0 10px black;
}

@media screen and (max-width: 768px) {
  .top-panel li a {
    font-size: 16px;
  }
}

.top-panel li:nth-child(1)::after {
  background-image: url(../images/company/mv.jpg);
  background-size: cover;
  background-position: center;
}

.top-panel li:nth-child(2)::after {
  background: url(../images/news/mv.jpg) no-repeat;
  background-size: cover;
  background-position: top;
}

.top-panel li:nth-child(3)::after {
  background-image: url(../images/about/mv.jpg);
  background-size: cover;
  background-position: top;
}

.top-panel li:nth-child(4)::after {
  background-image: url(../images/other/mv.jpg);
  background-size: cover;
  background-position: top;
}

.top-panel li:nth-child(5)::after {
  background-image: url(../images/welfare/mv.jpg);
  background-size: cover;
  background-position: top;
}

.top-panel li:nth-child(6)::after {
  background-image: url(../images/recruit/mv.jpg);
  background-size: cover;
  background-position: left bottom;
}

.top-panel li:nth-child(7)::after {
  background-image: url(../images/info/mv.jpg);
  background-size: cover;
  background-position: top;
}

.top-panel li:nth-child(8) {
  background: #FEBD3D;
}

.top-panel li:nth-child(8)::after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 2px solid white;
  border-top: 2px solid white;
  -webkit-transform: rotate(45deg);
  transform: rotate(135deg);
  left: 48%;
  top: 60%;
}

@media screen and (max-width: 768px) {
  .top-panel li:nth-child(8)::after {
    top: 80%;
  }
}

.top-panel li:nth-child(8):hover::after {
  top: 90%;
  opacity: 1;
}

.top-panel li:nth-child(8) a {
  text-shadow: none;
}

.top .slick-slide img {
  margin: 0 auto;
}

/*1.フェードインアニメーションの指定*/
.scrollanime {
  opacity: 0;
}

/*一瞬表示されるのを防ぐ*/
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/*2.上下の動きを指定*/
.updown {
  transform: translateY(-100px);
}

.downup {
  transform: translateY(100px);
}

/*3.左右の動きを指定*/
.sect02 {
  overflow: hidden;
}

/*横スクロールバーを隠す*/
.slide-right {
  transform: translateX(200px);
}

.slide-left {
  transform: translateX(-200px);
}

.fade-inner {
  opacity: 0;
}

.fadeInDown .fade-inner {
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-name: fadeInDown;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media screen and (max-width: 768px) {
  .recruit .page-link ul li {
    width: 100%;
  }
}

.recruit-side > a {
  padding: 15px;
  text-decoration: none;
  background: #FEBD3D;
  text-align: center;
  font-size: 14px;
  display: block;
  color: white;
}

.recruit-side-list {
  margin-top: 20px;
}

.recruit-side-list li {
  background: white;
  font-size: 14px;
  border: 1px solid #c5c5c5;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}

.recruit-side-list li::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid #c5c5c5;
  border-top: 2px solid #c5c5c5;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 10px;
  top: 45%;
}

.recruit-side-list li a {
  text-decoration: none;
  padding: 15px 10px;
  display: block;
  line-height: 1.3em;
}

.recruit-item h4 {
  line-height: 1;
}

.recruit-item h4 a {
  text-decoration: none;
}

.recruit-item p {
  letter-spacing: 0.1em;
}

.recruit-item-wrap {
  overflow-y: scroll;
  height: 670px;
}

.recruit-item-tag-wrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .recruit-item-tag-wrap {
    display: block;
  }
}

.recruit-item-tag-wrap p {
  width: 100px;
}

@media screen and (max-width: 768px) {
  .recruit-item-tag-wrap p {
    width: 100%;
  }
}

.recruit-item-tag > a {
  padding: 8px 20px;
  border-radius: 4px;
  background: #FEBD3D;
  color: white;
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .recruit-item-tag > a {
    margin-bottom: 10px;
    font-size: 13px;
  }
}

.recruit-item-tag.new a {
  background: #FF9DAB;
}

.recruit-item-tag.midway a {
  background: #81C2F9;
}

.recruit-item-tag.part a {
  background: #BDA6F8;
}

.recruit-item-text p {
  font-size: 14px;
}

.recruit-messege-img {
  position: relative;
}

.recruit-messege-img img {
  object-fit: cover;
  border-radius: 50%;
}

.recruit-messege-name {
  position: absolute;
  bottom: 65px;
  left: -20px;
  display: inline-block;
  margin-left: 30px;
  padding: 0.8rem 2rem 0.8rem 2rem;
  background: #fff;
  font-size: 14px;
  letter-spacing: 0.1em;
  filter: drop-shadow(0px 0px 10px #c5c5c5);
}

@media screen and (max-width: 768px) {
  .recruit-messege-name {
    font-size: 16px;
    bottom: 20px;
  }
}

.recruit-messege-name-post {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .recruit-messege-name-post {
    font-size: 13px;
  }
}

.recruit-messege-name strong {
  font-size: 22px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .recruit-messege-name strong {
    font-size: 18px;
  }
}

.recruit-messege-text {
  display: flex;
  align-items: center;
}

.recruit-messege h3 {
  border-color: #FF9DAB;
  line-height: 2em;
}

@media screen and (max-width: 768px) {
  .recruit-messege h3 {
    line-height: 1.8em;
  }
}

.recruit-detail-table th {
  padding: 20px !important;
  background-color: #F6F7FA !important;
  border-bottom: 2px solid white !important;
}

.recruit-detail-table td {
  font-size: 14px;
}

.recruit-detail-entry {
  background: #FEBD3D;
  padding: 40px;
  color: white;
  text-align: center;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .recruit-detail-entry {
    padding: 30px;
  }
}

.recruit-detail-entry h4 {
  color: white;
  margin-bottom: 20px;
}

.recruit-detail-entry a {
  background: white;
  color: #FEBD3D;
}

.recruit-detail-contact {
  background: #F6F7FA;
  padding: 40px;
  letter-spacing: 0.1em;
  font-size: 14px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .recruit-detail-contact {
    padding: 30px;
  }
}

.recruit-detail-contact a {
  font-size: 35px;
  color: #7ACC00;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
}

.recruit-detail .news-session-item-gaiyo {
  padding-top: 25px;
  margin-bottom: 30px;
}

.recruit-detail .news-session-item-gaiyo h4 {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .recruit-detail .news-session-item-gaiyo h4 {
    font-size: 18px;
  }
}

.recruit-detail .news-session-item-gaiyo p {
  font-size: 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .recruit-detail .news-session-item-gaiyo p {
    font-size: 13px;
  }
}

.recruit-detail .news-session-item-gaiyo p:nth-last-child(1) {
  margin-bottom: 0;
}

.recruit-detail .news-session-item-contact {
  margin: 0;
}

.recruit-detail .news-session-item-contact p.ttl {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .recruit-detail .news-session-item-contact p.ttl {
    font-size: 14px;
  }
}

.recruit-detail .news-session-item-contact a.tel {
  font-size: 35px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .recruit-detail .news-session-item-contact a.tel {
    font-size: 30px;
  }
}

.recruit-detail .news-session-item-contact a.c-button {
  padding: 15px;
  font-size: 14px;
  width: 300px;
  background: #FEBD3D;
  color: white;
  border: none;
}

.recruit-flow h3.pink {
  border-color: #FF9DAB;
}

.recruit-flow h3.blue {
  border-color: #81C2F9;
}

.recruit-flow h3.preple {
  border-color: #BDA6F8;
}

.recruit-flow ul {
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul {
    width: 100%;
  }
}

.recruit-flow ul li {
  color: white;
  width: calc(100% / 6);
  background: #FF9DAB;
  height: 180px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0.05em;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul li {
    width: calc(100% / 1);
    height: auto;
    padding-top: 30px;
  }
}

.recruit-flow ul li > div {
  line-height: 1.5;
  padding: 15px;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul li > div {
    width: auto;
  }
}

.recruit-flow ul li > div span {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  line-height: 1.5em;
  text-align: left;
}

.recruit-flow ul li::before, .recruit-flow ul li::after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.recruit-flow ul li::before {
  border: solid 160px transparent;
  border-right: solid 33px transparent;
  border-left: solid 33px #FFF;
  top: -70px;
  left: -17px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul li::before {
    border: solid 240px transparent;
    border-top: solid 33px #FFF;
    border-bottom: solid 33px transparent;
    top: -9px;
    left: -65px;
    z-index: 1;
  }
}

.recruit-flow ul li::after {
  border: solid 140px transparent;
  border-left: solid 30px #FF9DAB;
  border-right: solid 30px transparent;
  top: -50px;
  right: -35px;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul li::after {
    border: solid 220px transparent;
    border-top: solid 30px #FF9DAB;
    border-bottom: solid 30px transparent;
    top: -13px;
    right: -50px;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) {
  .recruit-flow ul li:not(:first-child):not(:last-child) {
    padding: 0 10px;
  }
  .recruit-flow ul li:first-child::before, .recruit-flow ul li:last-child::after {
    content: none;
  }
}

@media screen and (max-width: 768px) {
  .recruit-flow ul li:nth-child(1) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .recruit-flow ul li:nth-child(1)::before, .recruit-flow ul li:nth-child(1)::after {
    content: none;
  }
  .recruit-flow ul li:nth-last-child(1) {
    padding-bottom: 10px;
  }
}

.recruit-flow ul.blue li {
  background: #81C2F9;
}

.recruit-flow ul.blue li::after {
  border-left: solid 30px #81C2F9;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul.blue li::after {
    border: solid 220px transparent;
    border-top: solid 30px #81C2F9;
    border-bottom: solid 30px transparent;
  }
}

.recruit-flow ul.preple li {
  background: #BDA6F8;
}

.recruit-flow ul.preple li::after {
  border-left: solid 30px #BDA6F8;
}

@media screen and (max-width: 768px) {
  .recruit-flow ul.preple li::after {
    border: solid 220px transparent;
    border-top: solid 30px #BDA6F8;
    border-bottom: solid 30px transparent;
  }
}

.recruit-flow-wrap {
  margin-right: -30px;
}

@media screen and (max-width: 768px) {
  .recruit-flow-wrap {
    margin-right: 0;
  }
}

.recruit-new-item {
  display: block;
  text-decoration: none;
}

.recruit-new-item-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  background: #81C2F9;
  color: white;
  padding: 8px 15px;
}

.recruit-new-item-icon.part {
  background: #BDA6F8;
}

.recruit-new-text {
  position: relative;
}

@media screen and (max-width: 768px) {
  .recruit-new-text {
    padding-bottom: 30px;
  }
}

.recruit-new-tag {
  margin-bottom: 10px;
}

.recruit-new-tag span {
  border: 1px solid #7ACC00;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 5px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .recruit-new-tag span {
    padding: 3px 10px;
    font-size: 10px;
  }
}

.recruit-new-link {
  position: absolute;
  right: 60px;
  bottom: 20px;
  font-size: 12px;
  text-decoration: none;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .recruit-new-link {
    right: 45px;
    bottom: 5px;
    font-size: 10px;
  }
}

.recruit-new-link::before {
  content: "";
  width: 30px;
  height: 1px;
  background: #ababab;
  position: absolute;
  top: 8px;
  right: -40px;
}

.recruit-new-link::after {
  content: "";
  width: 10px;
  height: 1px;
  background: #ababab;
  position: absolute;
  top: 5px;
  right: -41px;
  transform: rotate(45deg);
}

.about .page-link ul li {
  padding: 5px;
  width: 25%;
}

@media screen and (max-width: 768px) {
  .about .page-link ul li {
    width: 100%;
  }
}

.about-flow {
  padding-left: 80px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 1px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .about-flow {
    padding-left: 60px;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-top: 0;
  }
}

.about-flow::before {
  content: "";
  width: 15px;
  height: 100%;
  background: #eee;
  margin-left: -8px;
  display: block;
  position: absolute;
  top: 0;
  left: 80px;
}

@media screen and (max-width: 768px) {
  .about-flow::before {
    width: 10px;
    left: 62px;
  }
}

.about-flow > li {
  position: relative;
  margin-bottom: 6vh;
}

@media screen and (max-width: 768px) {
  .about-flow > li {
    margin-bottom: 3vh;
  }
}

.about-flow > li .icon {
  font-size: 14px;
  display: block;
  position: absolute;
  top: 4px;
  left: -75px;
  z-index: 100;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 768px) {
  .about-flow > li .icon {
    font-size: 12px;
    left: -59px;
  }
}

.about-flow > li dl {
  padding-left: 70px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .about-flow > li dl {
    padding-left: 40px;
  }
}

.about-flow > li dl::before, .about-flow > li dl::after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
}

.about-flow > li dl::before {
  width: 7px;
  height: 7px;
  margin-top: -3px;
  background: #FF9DAB;
  border-radius: 50%;
  left: -4px;
}

.about-flow > li dl::after {
  width: 50px;
  border-bottom: 1px dashed #999;
  position: absolute;
  left: 5px;
}

@media screen and (max-width: 768px) {
  .about-flow > li dl::after {
    width: 20px;
  }
}

.about-flow > li dl dt {
  font-size: 18px;
  font-weight: 600;
  color: #FF9DAB;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .about-flow > li dl dt {
    font-size: 15px;
    margin-bottom: 5px;
    line-height: 1.5em;
  }
}

.about-flow > li dl dd {
  font-size: 14px;
  line-height: 1.5em;
}

@media screen and (max-width: 768px) {
  .about-flow > li dl dd {
    font-size: 12px;
  }
}

.about-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 768px) {
  .about-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.about-grid li {
  background: #FF9DAB;
  background-image: url(../images/about/img.jpg);
  background-size: cover;
  background-position: center;
  margin: 5px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .about-grid li {
    padding: 20px;
    margin: 2px;
  }
}

.about-grid li h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .about-grid li h4 {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 5px;
  }
}

.about-grid li h4 span {
  font-size: 40px;
}

@media screen and (max-width: 768px) {
  .about-grid li h4 span {
    font-size: 20px;
  }
}

.about-grid li p {
  letter-spacing: 0.1em;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .about-grid li p {
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
  }
}

.about-grid li:nth-child(odd) {
  background-image: none;
}

.about-grid li:nth-child(3) {
  background-color: #81C2F9;
}

.about-grid li:nth-child(5) {
  background-color: #BDA6F8;
}

.about-grid li:nth-child(7) {
  background-color: #FEB675;
}

.about-grid li:nth-child(9) {
  background-color: #FA6C60;
}

.about-grid li:nth-child(11) {
  background-color: #7ACC00;
}

@media screen and (max-width: 768px) {
  .about-toggle {
    display: none;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .about-toggle-btn {
    position: relative;
  }
  .about-toggle-btn::after {
    content: "+";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: #c5c5c5;
  }
}

@media screen and (max-width: 768px) {
  .about-map {
    overflow: scroll;
    max-height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .about-map-inner {
    width: 1000px;
  }
}

.about-map-text {
  background: #FEBD3D;
  padding: 5px;
  border-radius: 30px;
  text-align: center;
  color: white;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.about-map-text i {
  padding-right: 5px;
}

.about-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.about-list li {
  padding: 10px;
  width: 33.3333%;
}

@media screen and (max-width: 768px) {
  .about-list li {
    width: 100%;
    padding: 5px 10px;
  }
}

.about-list li a {
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .about-list li a {
    font-size: 14px;
  }
}

.about-list li a span {
  width: 25px;
  height: 25px;
  background: #F08200;
  color: white;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-right: 10px;
  font-family: "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .about-list li a span {
    font-size: 12px;
  }
}

.about-list li a span.green {
  background: #53A034;
}

.about-works-list {
  margin: -10px;
  margin-bottom: 40px;
}

.about-works-list > div {
  width: 33.333%;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .about-works-list > div {
    width: 100%;
  }
}

.about-works-list > div h4 {
  margin-bottom: 10px;
  border-bottom: 2px solid #FF9DAB;
  padding-bottom: 5px;
}

.about-works-list > div ul li {
  font-size: 14px;
  padding: 3px;
  position: relative;
  line-height: 1.5em;
  padding-left: 20px;
  overflow-wrap: break-word;
}

.about-works-list > div ul li::before {
  content: "●";
  color: #FF9DAB;
  position: absolute;
  top: 3px;
  left: 0;
}

.about-works-list > div:nth-child(2) h4 {
  border-color: #81C2F9;
}

.about-works-list > div:nth-child(2) ul li::before {
  color: #81C2F9;
}

.about-works-list > div:nth-child(3) h4 {
  border-color: #BDA6F8;
}

.about-works-list > div:nth-child(3) ul li::before {
  color: #BDA6F8;
}

.about-works-list > div:nth-child(4) h4 {
  border-color: #FEB675;
}

.about-works-list > div:nth-child(4) ul li::before {
  color: #FEB675;
}

.about-works-list > div:nth-child(5) h4 {
  border-color: #FA6C60;
}

.about-works-list > div:nth-child(5) ul li::before {
  color: #FA6C60;
}

.about-works-list > div:nth-child(6) h4 {
  border-color: #7ACC00;
}

.about-works-list > div:nth-child(6) ul li::before {
  color: #7ACC00;
}

.about-works-list-style {
  margin: -10px;
  margin-bottom: 40px;
}

.about-works-list-style ul li {
  padding: 5px;
  line-height: 1.5em;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .about-works-list-style ul li {
    width: 100%;
    font-size: 14px;
  }
}

.about-works-list-style ul li h4 {
  background: #FF9DAB;
  padding: 10px 20px;
  color: white;
  width: 180px;
  margin-right: 20px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .about-works-list-style ul li h4 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.about-works-list-style ul li:nth-child(2) h4 {
  background: #81C2F9;
}

.about-works-list-style ul li:nth-child(3) h4 {
  background: #BDA6F8;
}

.about-works-list-style ul li:nth-child(4) h4 {
  background: #FEB675;
}

.news-tab {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  margin-bottom: 40px;
}

.news-tab li {
  padding: 5px;
  width: 16.66666%;
}

@media screen and (max-width: 768px) {
  .news-tab li {
    width: 50%;
  }
}

.news-tab li a {
  display: block;
  padding: 15px;
  font-size: 14px;
  text-align: center;
  background: #c5c5c5;
  text-decoration: none;
  color: white;
}

.news-tab li.current a {
  background: #7ACC00;
}

.news-item a {
  text-decoration: none;
  display: block;
}

@media screen and (max-width: 768px) {
  .news-item a {
    display: flex;
    flex-wrap: wrap;
  }
}

.news-item-img {
  position: relative;
}

@media screen and (max-width: 768px) {
  .news-item-img {
    width: 50%;
    padding-right: 20px;
  }
}

.news-item-img img {
  box-shadow: 0px 0px 10px #c5c5c5;
}

.news-item-img span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 10px;
  background: #FEBD3D;
  color: white;
  font-size: 12px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .news-item-img span {
    padding: 5px;
    font-size: 10px;
  }
}

.news-item-img span.info {
  background: #FF9DAB;
}

.news-item-img span.orient {
  background: #81C2F9;
}

.news-item-img span.tour {
  background: #BDA6F8;
}

.news-item-img span.fair {
  background: #FEB675;
}

.news-item-img span.event {
  background: #FA6C60;
}

.news-item-text {
  padding: 10px 0 0;
}

@media screen and (max-width: 768px) {
  .news-item-text {
    width: 50%;
    padding-top: 0;
  }
  .news-item-text h4 {
    font-size: 12px;
    line-height: 1.5;
  }
}

.news-item-text-date {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .news-item-text-date {
    font-size: 10px;
  }
}

.news-session-item {
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px #c5c5c5;
}

@media screen and (max-width: 768px) {
  .news-session-item {
    padding-bottom: 10px;
  }
}

.news-session-item-date {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .news-session-item-date {
    font-size: 10px;
    margin-bottom: 0;
  }
}

.news-session-item a {
  text-decoration: none;
}

.news-session-item-gaiyo {
  border: 1px solid #c5c5c5;
  padding: 15px 20px;
  position: relative;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .news-session-item-gaiyo {
    margin-top: 10px;
  }
}

.news-session-item-gaiyo h4 {
  background: white;
  position: absolute;
  top: -15px;
  left: 15px;
  padding: 5px;
  letter-spacing: 0.1em;
  font-size: 14px;
}

.news-session-item-gaiyo p {
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.news-session-item-contact {
  background: #F6F7FA;
  padding: 20px;
  margin: -20px;
  margin-top: 0;
}

.news-session-item-contact h4 {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.news-session-item-contact p {
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.news-session-item-contact p.ttl {
  border-left: 3px solid #7ACC00;
  padding-left: 10px;
}

.news-session-item-contact a.tel {
  color: #7ACC00;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
}

.news-session-item-contact a.c-button {
  letter-spacing: 0.1em;
  padding: 10px 40px;
  font-size: 14px;
}

.other-us-img {
  position: relative;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .other-us-img {
    padding: 15px 30px 0;
    margin-bottom: 5px;
  }
}

.other-us-img img {
  object-fit: cover;
  border-radius: 50%;
}

.other-us-name {
  position: absolute;
  bottom: 10px;
  left: -20px;
  display: inline-block;
  height: 50px;
  margin-left: 30px;
  padding: 0.9rem 2rem 1rem 3rem;
  color: white;
  background: #FF9DAB;
  font-size: 20px;
  letter-spacing: 0.1em;
  transform: rotate(-5deg);
}

@media screen and (max-width: 768px) {
  .other-us-name {
    left: -15px;
    height: 40px;
    padding: 10px 30px 0;
    font-size: 18px;
    transform: rotate(-3deg);
    margin-bottom: 5px;
  }
}

.other-us-name::before {
  position: absolute;
  top: 0;
  left: -22px;
  content: '';
  border-width: 25px 23px 25px 0;
  border-style: solid;
  border-color: transparent #FF9DAB transparent transparent;
}

@media screen and (max-width: 768px) {
  .other-us-name::before {
    left: -17px;
    border-width: 20px 18px 20px 0;
  }
}

.other-us-name::after {
  position: absolute;
  top: calc(50% - 7px);
  left: -8px;
  width: 12px;
  height: 12px;
  content: '';
  border-radius: 50%;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .other-us-name::after {
    top: calc(50% - 5px);
    left: -3px;
    width: 8px;
    height: 8px;
  }
}

.other-us-name-post {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .other-us-name-post {
    font-size: 13px;
  }
}

.other-us-name-kana {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .other-us-name-kana {
    font-size: 11px;
  }
}

.other-us-name.blue {
  background: #81C2F9;
}

.other-us-name.blue::before {
  border-color: transparent #81C2F9 transparent transparent;
}

.other-us-name.purple {
  background: #BDA6F8;
}

.other-us-name.purple::before {
  border-color: transparent #BDA6F8 transparent transparent;
}

.other-us-name.orange {
  background: #FEB675;
}

.other-us-name.orange::before {
  border-color: transparent #FEB675 transparent transparent;
}

.other-us-name.red {
  background: #FA6C60;
}

.other-us-name.red::before {
  border-color: transparent #FA6C60 transparent transparent;
}

.other-us-history {
  line-height: 1.3em;
  letter-spacing: 0.05em;
  text-align: center;
}

.other-us h3 {
  border-color: #FF9DAB;
  line-height: 2em;
}

@media screen and (max-width: 768px) {
  .other-us h3 {
    line-height: 1.8em;
  }
}

.other-us-messege {
  margin: 1rem -10px;
  padding: 0.5rem 2rem;
  background: #FF9DAB;
  border: none;
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 16px;
  padding-right: 50px;
  color: white;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .other-us-messege {
    font-size: 13px;
    line-height: 1.5em !important;
    letter-spacing: 0;
    padding: 0.8rem 2.5rem;
    padding-left: 15px;
  }
}

.other-us-messege::before {
  position: absolute;
  content: '';
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 10px solid #FF9DAB;
  border-left: 10px solid transparent;
}

.other-us-messege::after {
  position: absolute;
  content: '';
  right: -30px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 50px solid white;
  border-right: 50px solid transparent;
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .other-us-messege::after {
    top: -5%;
  }
}

.other-us-messege.blue {
  background: #81C2F9;
}

.other-us-messege.blue::before {
  border-top: 10px solid #81C2F9;
}

.other-us-messege.purple {
  background: #BDA6F8;
}

@media screen and (max-width: 768px) {
  .other-us-messege.purple {
    padding-right: 60px;
  }
}

.other-us-messege.purple::before {
  border-top: 10px solid #BDA6F8;
}

@media screen and (max-width: 768px) {
  .other-us-messege.purple::after {
    top: -4%;
    right: -50px;
    border-width: 70px;
  }
}

.other-us-messege.orange {
  background: #FEB675;
}

.other-us-messege.orange::before {
  border-top: 10px solid #FEB675;
}

.other-us-messege.red {
  background: #FA6C60;
}

.other-us-messege.red::before {
  border-top: 10px solid #FA6C60;
}

.other-us .slick {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .other-us .slick {
    margin: 0 30px 15px;
  }
}

.other-us .slick .slick-dots {
  bottom: -20px;
}

.other-us .slick .slick-dots li {
  height: 5px;
  width: 30px;
}

.other-us .slick .slick-dots li button::before {
  content: "";
  height: 5px;
  width: 30px;
  background: #FF9DAB;
}

.other-us .slick.blue .slick-dots li button::before {
  background: #81C2F9;
}

.other-us .slick.purple .slick-dots li button::before {
  background: #BDA6F8;
}

.other-us .slick.orange .slick-dots li button::before {
  background: #FEB675;
}

.other-us .slick.red .slick-dots li button::before {
  background: #FA6C60;
}

.other-faq-list {
  margin-bottom: 30px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .other-faq-list {
    margin-bottom: 20px;
  }
}

.other-faq-list dt {
  background: #E3F0D0;
  padding: 15px;
}

@media screen and (max-width: 768px) {
  .other-faq-list dt {
    font-size: 14px;
    line-height: 1.5em;
    position: relative;
    padding-right: 25px;
    letter-spacing: 0.05em;
  }
  .other-faq-list dt::after {
    content: "+";
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 25px;
    color: #c5c5c5;
  }
}

.other-faq-list dd {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5em;
  text-align: justify;
  text-justify: inter-ideograph;
}

@media screen and (max-width: 768px) {
  .other-faq-list dd {
    font-size: 13px;
    line-height: 1.5em;
    padding: 10px 0;
    display: none;
  }
}

.welfare-grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .welfare-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: auto;
  }
}

.welfare-grid li {
  background: #FF9DAB;
  background-image: url(../images/img02.jpg);
  background-size: cover;
  background-position: center;
  margin: 5px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .welfare-grid li {
    padding: 20px;
  }
}

.welfare-grid li h4 {
  font-size: 24px;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .welfare-grid li h4 {
    font-size: 18px;
  }
}

.welfare-grid li p {
  letter-spacing: 0.1em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .welfare-grid li p.u-text-x-small {
    font-size: 10px;
  }
}

.welfare-grid li:nth-child(2) {
  background-color: #81C2F9;
}

.welfare-grid li:nth-child(3) {
  background-color: #BDA6F8;
}

.welfare-grid.second {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: auto;
}

@media screen and (max-width: 768px) {
  .welfare-grid.second {
    grid-template-columns: 1fr;
  }
}

.welfare-grid.second li {
  background: white;
  margin: 5px;
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border: 5px solid #E3F0D0;
  color: #717171;
}

@media screen and (max-width: 768px) {
  .welfare-grid.second li {
    padding: 10px;
  }
}

.welfare-grid.second li h4 {
  font-size: 18px;
  line-height: 1.4em;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .welfare-grid.second li h4 {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 3px;
  }
}

.welfare-grid.second li p {
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .welfare-grid.second li p {
    font-size: 12px;
  }
}

.welfare-grid.second li p.u-text-x-small {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .welfare-grid.second li p.u-text-x-small {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .welfare .c-h2 span {
    line-height: 1.3em;
    margin-bottom: 5px;
    letter-spacing: 0;
    font-size: 13px;
  }
}

.welfare #holiday .c-h2 span {
  color: #FF9DAB;
}

.welfare #education .c-h2 span {
  color: #81C2F9;
}

.welfare #career .c-h2 span {
  color: #BDA6F8;
}

.welfare #housing .c-h2 span {
  color: #FEB675;
  line-height: 1.3em;
  margin-bottom: 5px;
}

.welfare-education-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.welfare-education-list li {
  width: calc(100%/3);
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .welfare-education-list li {
    width: calc(100%/2);
    font-size: 13px;
  }
}

.welfare-education-list li span {
  display: block;
  padding: 15px;
  border-radius: 30px;
  background: white;
  border: 3px solid #81C2F9;
  text-align: center;
  letter-spacing: 0.1em;
}

.welfare-education-list li:nth-child(1) {
  width: 100%;
}

.welfare-career-graph {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 200px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph {
    margin-top: 0px;
  }
}

.welfare-career-graph li {
  width: calc(100%/7);
  position: relative;
}

.welfare-career-graph li::after {
  background: #FAF118;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 20%;
}

.welfare-career-graph li::before {
  content: "保育士";
  position: absolute;
  bottom: 23%;
  left: 0;
  width: 90%;
  text-align: center;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(2)::after {
  background: #FED918;
  height: 30%;
}

.welfare-career-graph li:nth-child(2)::before {
  content: "クラス担任";
  bottom: 33%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(2)::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(3)::after {
  background: #F5C30E;
  height: 40%;
}

.welfare-career-graph li:nth-child(3)::before {
  content: "幼児リーダー";
  bottom: 43%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(3)::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(4)::after {
  background: #F59F15;
  height: 50%;
}

.welfare-career-graph li:nth-child(4)::before {
  content: "乳児リーダー";
  bottom: 53%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(4)::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(5)::after {
  background: #E66C06;
  height: 60%;
}

.welfare-career-graph li:nth-child(5)::before {
  content: "副主任";
  bottom: 63%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(5)::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(6)::after {
  background: #E04606;
  height: 70%;
}

.welfare-career-graph li:nth-child(6)::before {
  content: "主任";
  bottom: 73%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(6)::before {
    font-size: 10px;
  }
}

.welfare-career-graph li:nth-child(7)::after {
  background: #DC0D07;
  height: 80%;
}

.welfare-career-graph li:nth-child(7)::before {
  content: "園長";
  bottom: 83%;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .welfare-career-graph li:nth-child(7)::before {
    font-size: 10px;
  }
}

.welfare-career-graph-wrap {
  position: relative;
}

.welfare-career-graph-arrow {
  position: absolute;
  bottom: 80%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.welfare-career-graph-arrow::before, .welfare-career-graph-arrow::after {
  position: absolute;
  content: "";
}

.welfare-career-graph-arrow::before {
  right: 14%;
  top: -10%;
  height: 15px;
  width: 15px;
  border: 15px solid transparent;
  border-left: 15px solid #DC0D07;
  -webkit-transform: rotate(-12deg);
  transform: rotate(-12deg);
}

@media screen and (max-width: 768px) {
  .welfare-career-graph-arrow::before {
    right: 14%;
    top: 0;
    -webkit-transform: rotate(-13deg);
    transform: rotate(-13deg);
  }
}

.welfare-career-graph-arrow::after {
  top: 20%;
  left: 13%;
  border: 0 solid transparent;
  width: 70%;
  height: 9px;
  background: #DC0D07;
  -webkit-transform: rotate(-12deg);
  transform: rotate(-12deg);
}

@media screen and (max-width: 768px) {
  .welfare-career-graph-arrow::after {
    top: 23%;
    left: 13%;
    width: 67%;
    -webkit-transform: rotate(-17deg);
    transform: rotate(-17deg);
  }
}

.welfare-career-graph-king {
  position: absolute;
  bottom: 87%;
  width: 12%;
  right: 1.8%;
}

.welfare-career-graph-wakaba {
  position: absolute;
  bottom: 28%;
  left: 1.5%;
  width: 10%;
}
