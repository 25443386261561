@charset "UTF-8";
.welfare {
    &-grid {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        height: 300px;
        @include view-at(sp) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            height: auto;
        }

        li {
            background: $color-btn01;
            background-image: url(../images/img02.jpg);
            background-size: cover;
            background-position: center;
            margin: 5px;
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-align: center;
            @include view-at(sp) {
                padding: 20px;
            }
            
            h4 {
                font-size: 24px;
                margin-bottom: 5px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 18px;
                }
            }
            p {
                letter-spacing: 0.1em;
                text-align: center;
                &.u-text-x-small {
                    @include view-at(sp) {
                        font-size: 10px;
                    }
                }
            }
            &:nth-child(2) {
                background-color: $color-btn02;
            }
            &:nth-child(3) {
                background-color: $color-btn03;
            }
        }

        &.second {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            height: auto;
            @include view-at(sp) {
                grid-template-columns: 1fr;
            }
    
            li {
                background: white;
                margin: 5px;
                padding: 30px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;
                border: 5px solid $color-green-snow;
                color: $color-gray-dark;
                @include view-at(sp) {
                    padding: 10px;
                }
                
                h4 {
                    font-size: 18px;
                    line-height: 1.4em;
                    margin-bottom: 5px;
                    @include view-at(sp) {
                        font-size: 14px;
                        line-height: 1;
                        margin-bottom: 3px;
                    }
                }
                p {
                    letter-spacing: 0.1em;
                    text-align: center;
                    font-size: 14px;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                    &.u-text-x-small {
                        font-size: 12px;
                        @include view-at(sp) {
                            font-size: 10px;
                        }
                    }
            
                }
            }
        }
    }
    .c-h2 {
        span {
            @include view-at(sp) {
                line-height: 1.3em;
                margin-bottom: 5px;
                letter-spacing: 0;
                font-size: 13px;
            }
        }
    }
    #holiday {
        .c-h2 {
            span {
                color: $color-btn01;
            }
        }
    }
    #education {
        .c-h2 {
            span {
                color: $color-btn02;
            }
        }
    }
    #career {
        .c-h2 {
            span {
                color: $color-btn03;
            }
        }
    }
    #housing {
        .c-h2 {
            span {
                color: $color-btn04;
                line-height: 1.3em;
                margin-bottom: 5px;
            }
        }
    }
    &-education {
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: -5px;

            li {
                width: calc(100%/3);
                padding: 5px;
                @include view-at(sp) {
                    width: calc(100%/2);
                    font-size: 13px;
                }
                
                span {
                    display: block;
                    padding: 15px;
                    border-radius: 30px;
                    background: white;
                    border: 3px solid $color-btn02;
                    text-align: center;
                    letter-spacing: 0.1em;
                }
                &:nth-child(1) {
                    width: 100%;
                }
            }
        }
    }

    &-career {
        &-graph {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 200px;
            margin-top: 40px;
            @include view-at(sp) {
                margin-top: 0px;
            }

            li {
                width: calc(100%/7);
                position: relative;
                &::after {
                    background: #FAF118;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 90%;
                    height: 20%;
                }
                &::before {
                    content: "保育士";
                    position: absolute;
                    bottom: 23%;
                    left: 0;
                    width: 90%;
                    text-align: center;
                    font-size: 13px;
                    @include view-at(sp) {
                        font-size: 10px;
                    }
                }
                &:nth-child(2) {
                    &::after {
                        background: #FED918;
                        height: 30%;
                    }
                    &::before {
                        content: "クラス担任";
                        bottom: 33%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
                &:nth-child(3) {
                    &::after {
                        background: #F5C30E;
                        height: 40%;
                    }
                    &::before {
                        content: "幼児リーダー";
                        bottom: 43%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
                &:nth-child(4) {
                    &::after {
                        background: #F59F15;
                        height: 50%;
                    }
                    &::before {
                        content: "乳児リーダー";
                        bottom: 53%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
                &:nth-child(5) {
                    &::after {
                        background: #E66C06;
                        height: 60%;
                    }
                    &::before {
                        content: "副主任";
                        bottom: 63%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
                &:nth-child(6) {
                    &::after {
                        background: #E04606;
                        height: 70%;
                    }
                    &::before {
                        content: "主任";
                        bottom: 73%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
                &:nth-child(7) {
                    &::after {
                        background: #DC0D07;
                        height: 80%;
                    }
                    &::before {
                        content: "園長";
                        bottom: 83%;
                        font-size: 13px;
                        @include view-at(sp) {
                        font-size: 10px;
                    }
                    }
                }
            }

            &-wrap {
                position: relative;
            }

            &-arrow {
                position: absolute;
                bottom: 80%;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &::before,
                &::after{
                    position: absolute;
                    content: "";
                }
                &::before {
                    right: 14%;
                    top: -10%;
                    height: 15px;
                    width: 15px;
                    border: 15px solid transparent;
                    border-left: 15px solid #DC0D07;
                    -webkit-transform: rotate(-12deg);
                    transform: rotate(-12deg);
                    @include view-at(sp) {
                        right: 14%;
                        top: 0;
                        -webkit-transform: rotate(-13deg);
                        transform: rotate(-13deg);
                    }
                }
                &::after {
                    top: 20%;
                    left: 13%;
                    border: 0 solid transparent;
                    width: 70%;
                    height: 9px;
                    background: #DC0D07;
                    -webkit-transform: rotate(-12deg);
                    transform: rotate(-12deg);
                    @include view-at(sp) {
                        top: 23%;
                        left: 13%;
                        width: 67%;
                        -webkit-transform: rotate(-17deg);
                        transform: rotate(-17deg);
                    }
                }
            }
            &-king {
                position: absolute;
                bottom: 87%;
                width: 12%;
                right: 1.8%;
            }
            &-wakaba {
                position: absolute;
                bottom: 28%;
                left: 1.5%;
                width: 10%;
            }
        }
    }
}
