/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-white;
  box-shadow: 0px 0px 10px $color-gray-light;
  position: relative;
  
  &--border {
    border: 1px solid $color-gray-light;
    background-color: $color-white;
  }

  &--rounded {
    border-radius: 8px;
  }

  &-contents {
    padding: 80px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--middle {
      padding: 40px;
      @include view-at(sp) {
        padding: 20px;
      }
    }
    &--narrow {
      padding: 20px;
      @include view-at(sp) {
        padding: 20px;
      }
    }
  }
}
