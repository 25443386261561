/* --------------------------
見出し
-------------------------- */



.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  text-align: center;
  margin: 0 auto 40px;
  font-weight: $normal;
  font-size: nth($font-size, 1);
  line-height: 1.5;
  letter-spacing: 0.15em;
  text-indent: 0.2em;

  @include view-at(sp) {
    font-size: 20px;
    margin: 0 auto 20px;
  }

  span {
    display: block;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.15em;
    font-size: 18px;
    margin-bottom: 5px;
    color: #7ACC00;
    @include view-at(sp) {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 10px;
    }
  }

  &.pink {
    span {
      color:$color-btn01;
    }
  }
  &.blue {
    span {
      color:$color-btn02;
    }
  }
  &.purple {
    span {
      color:$color-btn03;
    }
  }
  &.orange {
    span {
      color:$color-btn04;
    }
  }
  &.red {
    span {
      color:$color-btn05;
    }
  }
  
}

.c-h3 {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.08em;
  font-weight: $normal;
  margin-bottom: 30px;
  border-left: 5px solid #7ACC00;
  padding-left: 20px;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &.blue {
    border-left: 5px solid $color-btn02;
  }
  &.purple {
    border-left: 5px solid $color-btn03;
  }
  &.orange {
    border-left: 5px solid $color-btn04;
  }
  &.red {
    border-left: 5px solid $color-btn05;
  }
}

.c-h4 {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 3);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
    margin-bottom: 10px;
  }
}

.c-h5 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  border: none;
  padding: 0;
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.08em;
  font-weight: $normal;
  margin-bottom: 30px;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &.pink {
    color: $color-btn01;
  }
  &.blue {
    color: $color-btn02;
  }
}