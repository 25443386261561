
.p-sidenav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
  @include view-at(sp) {
    display: none;
  }

  a {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    display: block;
    padding: 40px 10px 20px;
    margin-bottom: 10px;
    background: $color-yellow;
    text-decoration: none;
    color: white;
    letter-spacing: 0.1em;
    position: relative;
    @media all and (-ms-high-contrast: none) {
      height: 180px;

      &:nth-child(3) {
        height: 230px;
      }
    }
    
    &::before {
      content:"";
      display:block;
      position:absolute;
      width:16px;
      height:16px;
      background:#fff;
      border-radius:50%;
      left: 10px;
      top: 14px;
    }
    &::after {
      content:"";
      display:block;
      position:absolute;
      width:6px;
      height:6px;
      border-right:2px solid  $color-yellow;
      border-top:2px solid  $color-yellow;
      -webkit-transform:rotate(45deg);
      transform:rotate(45deg);
      left: 14px;
      top: 19px;
    }

    &.pink {
      background: $color-btn01;
      &::after {
        border-color: $color-btn01;
      }
    }
    &.blue {
      background: $color-btn02;
      &::after {
        border-color: $color-btn02;
      }
    }
  }
}



