.p-header {
  padding: 10px 0 0;
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 2px rgba(0,0,0,0.4);

  @include view-at(sp) {
    height: 50px;
    padding: 0;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $contents-width;
    margin: 0 auto;
    padding: 0 $contents-padding 10px;
    @include view-at(sp) {
      padding: 10px 0;
      height: 100%;
      padding-right: 60px;
    }
  }

  &-logo {
    text-align: center;
    
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      letter-spacing: 0.05em;
      @include view-at(tab) {
        margin-left: 10px;
        font-size: 11px;
        display: block;
        letter-spacing: 0.1em;
      }
      img {
        max-width: 250px;
        padding-right: 20px;
        @include view-at(sp) {
          width: 170px;
          padding-right: 0;
          display: block;
          margin-bottom: 2px;
        }
      }
    }
  }

  &-cta {
    display: flex;
    align-items: center;
  }

  &-tel {
    color: $color-main;
    text-decoration: none;
    padding-right: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    display: flex;
    align-items: center;
    @include view-at(sp) {
      font-size: 0;
    }

    i {
      font-size: 25px;
      padding-right: 10px;
      @include view-at(sp) {
        padding: 0;
        font-size: 20px;
        color: $color-sub;
      }
    }
  }
  &-entry {
    @include view-at(sp) {
      font-size: 0;
      padding: 0;
      background: none;
    }
    i {
      padding-right: 5px;
      @include view-at(sp) {
        padding: 0;
        font-size: 25px;
        color: $color-sub;
      }
    }
  }
}
