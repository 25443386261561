/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background-color: $color-white;
  border: 1px solid $color-gray;
  position: relative;
  font-size: 14px;
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &--arrow {
    letter-spacing: 0.1em;
    border: 1px solid $color-gray-light;
    width: 300px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-right: 2px solid $color-gray;
      border-top: 2px solid  $color-gray;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      right: 14px;
      top: 45%;
    }

    &-left {
      letter-spacing: 0.1em;
      border: 1px solid $color-gray-light;
      width: 300px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-right: 2px solid  $color-gray;
        border-top: 2px solid  $color-gray;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        left: 14px;
        top: 45%;
      }
    }
  }

  &--primary {
    background-color: $color-yellow;
    border: 0;
    color: $color-white;
    border-radius: 50px;
    font-size: 16px;
    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
  }

  &-more {
    position: absolute;
    top: 15px;
    right: 0;
  }
}
