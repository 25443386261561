@charset "UTF-8";
.about {
    .page-link ul li {
        padding: 5px;
        width: 25%;
        @include view-at(sp) {
            width: 100%;
        }
    }
    &-flow {
        padding-left: 80px;
        position: relative;
        padding-top: 20px;
        padding-bottom: 1px;
        margin-bottom: 40px;
        @include view-at(sp) {
            padding-left: 60px;
            margin-bottom: 0;
            padding-bottom: 10px;
            padding-top: 0;
        }
        &::before {
            content: "";
            width: 15px;
            height: 100%;
            background: #eee;
            margin-left: -8px;
            display: block;
            position: absolute;
            top: 0;
            left: 80px;
            @include view-at(sp) {
                width: 10px;
                left: 62px;
            }
          }
          > li {
            position: relative;
            margin-bottom: 6vh;
            @include view-at(sp) {
                margin-bottom: 3vh;
            }
            .icon {
                font-size: 14px;
                display: block;
                position: absolute;
                top: 4px;
                left: -75px;
                z-index: 100;
                font-family: 'Montserrat', sans-serif;
                @include view-at(sp) {
                    font-size: 12px;
                    left: -59px;
                }
            }
            dl {
                padding-left: 70px;
                position: relative;
                @include view-at(sp) {
                    padding-left: 40px;
                }
                &::before,&::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 10px;
                }
                &::before {
                    width: 7px;
                    height: 7px;
                    margin-top: -3px;
                    background: $color-btn01;
                    border-radius: 50%;
                    left: -4px;
                }
                &::after {
                    width: 50px;
                    border-bottom: 1px dashed #999;
                    position: absolute;
                    left: 5px;
                    @include view-at(sp) {
                        width: 20px;
                    }
                }
                dt {
                    font-size: 18px;
                    font-weight: 600;
                    color: $color-btn01;
                    margin-bottom: 10px;
                    @include view-at(sp) {
                        font-size: 15px;
                        margin-bottom: 5px;
                        line-height: 1.5em;
                    }
                }
                dd {
                    font-size: 14px;
                    line-height: 1.5em;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                }
            }
          }

    }
    &-grid {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        @include view-at(sp) {
            grid-template-columns: 1fr 1fr;
        }

        li {
            background: $color-btn01;
            background-image: url(../images/about/img.jpg);
            background-size: cover;
            background-position: center;
            margin: 5px;
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-align: center;
            @include view-at(sp) {
                padding: 20px;
                margin: 2px;
            }
            
            h4 {
                font-family: 'Montserrat', sans-serif;
                font-size: 24px;
                margin-bottom: 0px;
                @include view-at(sp) {
                    font-size: 14px;
                    line-height: 1;
                    margin-bottom: 5px;
                }
                span {
                    font-size: 40px;
                    @include view-at(sp) {
                        font-size: 20px;
                    }
                }
            }
            p {
                letter-spacing: 0.1em;
                font-size: 18px;
                @include view-at(sp) {
                    font-size: 12px;
                    line-height: 1.2em;
                    text-align: center;
                }
            }

            &:nth-child(odd) {
                background-image: none;
            }
            &:nth-child(3) {
                background-color: $color-btn02;
            }
            &:nth-child(5) {
                background-color: $color-btn03;
            }
            &:nth-child(7) {
                background-color: $color-btn04;
            }
            &:nth-child(9) {
                background-color: $color-btn05;
            }
            &:nth-child(11) {
                background-color: $color-main;
            }
        }
    }
    &-toggle {
        @include view-at(sp) {
            display: none;
            margin-top: 20px;
        }
        &-btn {
            @include view-at(sp) {
                position: relative;
                &::after {
                    content: "+";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 25px;
                    color: $color-gray-light;
                }
            }
        }
    }
    &-map {
        @include view-at(sp) {
            overflow: scroll;
            max-height: 450px;
        }
        &-inner {
            @include view-at(sp) {
                width: 1000px;
            }
        }
        &-text {
            background: $color-sub;
            padding: 5px;
            border-radius: 30px;
            text-align: center;
            color: white;
            font-size: 12px;
            letter-spacing: 0.1em;
            i {
                padding-right: 5px;
            }
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;

        li {
            padding: 10px;
            width: 33.3333%;
            @include view-at(sp) {
                width: 100%;
                padding: 5px 10px;
                
            }
            a {
                display: block;
                text-decoration: none;
                display: flex;
                align-items: center;
                @include view-at(sp) {
                    font-size: 14px;
                }
                span {
                    width: 25px;
                    height: 25px;
                    background: #F08200;
                    color: white;
                    display: inline-block;
                    text-align: center;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                    margin-right: 10px;
                    font-family: "メイリオ", Meiryo, sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                    &.green {
                        background: #53A034;
                    }
                }
            }
        }
    }
    &-works {
        &-list {
            margin: -10px;
            margin-bottom: 40px;

            > div {
                width: 33.333%;
                padding: 10px;
                @include view-at(sp) {
                    width: 100%;
                }

                h4 {
                    margin-bottom: 10px;
                    border-bottom: 2px solid $color-btn01;
                    padding-bottom: 5px;
                }
                ul {
                    li {
                        font-size: 14px;
                        padding: 3px;
                        position: relative;
                        line-height: 1.5em;
                        padding-left: 20px;
                        overflow-wrap: break-word;
                        &::before {
                            content: "●";
                            color: $color-btn01;
                            position: absolute;
                            top: 3px;
                            left: 0;
                        }
                    }
                }

                &:nth-child(2) {
                    h4 {
                        border-color: $color-btn02;
                    }
                    ul {
                        li {
                            &::before {
                                color: $color-btn02;
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    h4 {
                        border-color: $color-btn03;
                    }
                    ul {
                        li {
                            &::before {
                                color: $color-btn03;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    h4 {
                        border-color: $color-btn04;
                    }
                    ul {
                        li {
                            &::before {
                                color: $color-btn04;
                            }
                        }
                    }
                }
                &:nth-child(5) {
                    h4 {
                        border-color: $color-btn05;
                    }
                    ul {
                        li {
                            &::before {
                                color: $color-btn05;
                            }
                        }
                    }
                }
                &:nth-child(6) {
                    h4 {
                        border-color: $color-btn06;
                    }
                    ul {
                        li {
                            &::before {
                                color: $color-btn06;
                            }
                        }
                    }
                }
            }
            

            &-style {
                margin: -10px;
                margin-bottom: 40px;
                ul {
                    li {
                        padding: 5px;
                        line-height: 1.5em;
                        font-size: 16px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        @include view-at(sp) {
                            width: 100%;
                            font-size: 14px;
                        }
                        h4 {
                            background: $color-btn01;
                            padding: 10px 20px;
                            color: white;
                            width: 180px;
                            margin-right: 20px;
                            letter-spacing: 0.1em;
                            @include view-at(sp) {
                                width: 100%;
                                margin-right: 0;
                                margin-bottom: 5px;
                            }
                        }
                        &:nth-child(2) {
                            h4 {
                                background: $color-btn02;
                            }
                        }
                        &:nth-child(3) {
                            h4 {
                                background: $color-btn03;
                            }
                        }
                        &:nth-child(4) {
                            h4 {
                                background: $color-btn04;
                            }
                        }
                    }
                }
            }
        }
    }
}